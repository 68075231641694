.brandbook-module {
  --btn-py: var(--offset-12);
  --btn-px: var(--offset-32);
  --btn-br: var(--br);

  /* TEXT */
  --btn-fw: var(--text-fw-medium);
  --btn-fs: var(--text-body1-fs);
  --btn-lh: var(--text-body1-lh);
  --btn-ff: var(--ff-primary);

  --btn-transform-scale: scale(6);
}

@media (max-width: 1280px) {
  .brandbook-module {
    --btn-transform-scale: scale(3.8);
  }
}

@media (max-width: 1024px) {
  .brandbook-module {
    --btn-transform-scale: scale(4);
  }
}

@media (max-width: 768px) {
  .brandbook-module {
    --btn-transform-scale: scale(3);
  }
}

@media (max-width: 576px) {
  .brandbook-module {
    --btn-transform-scale: scale(2);
  }
}
