.offer-module {
  --model-wrapper-count: 3;
  --model-wrapper-gap: var(--offset-32);
  --model-wrapper-margin: var(--offset-0);

  --model-container-margin-bottom: var(--offset-32);

  --model-table-view-row-p: var(--offset-0) var(--offset-32);
}
@media (max-width: 1280px) {
  .offer-module {
    --model-wrapper-count: 2;
    --model-table-view-row-p: var(--offset-0) var(--offset-16);
    --model-wrapper-margin: var(--offset-32);
  }
}
@media (max-width: 768px) {
  .offer-module {
    --model-wrapper-count: 1;
    --model-table-view-row-p: var(--offset-0) var(--offset-16);
    --model-wrapper-margin: var(--offset-8);
  }
}
@media (max-width: 576px) {
  .offer-module {
    --model-wrapper-margin: var(--offset-0);
  }
}
