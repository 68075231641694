.brandbook-module {
  --checkbox-w: 1rem; /* size of the checkbox */
  --checkbox-h: 1rem; /* size of the checkbox */
  --checkbox-mr: var(--offset-12);
  --checkbox-br: 0.25rem;
  --checkbox-bw: var(--bw-1);
  --checkbox-bs: var(--b-solid);
  --checkbox-fs: var(--text-body1-fs);
  --checkbox-lh: var(--text-body1-lh);
  --checkbox-fw: var(--text-fw-regular);
  --checkbox-transform-scale: scale(20);
  --checkbox-ff: var(--ff-primary);
}

@media (max-width: 1280px) {
  .brandbook-module {
    --checkbox-transform-scale: scale(15);
  }
}

@media (max-width: 768px) {
  .brandbook-module {
    --checkbox-transform-scale: scale(10);
  }
}

@media (max-width: 576px) {
  .brandbook-module {
    --checkbox-transform-scale: scale(7);

  }
}
