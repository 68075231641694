.brandbook-module {
  --shade-simple-view-width: 17.5rem;
  --shade-simple-view-height-sm: 11.1%;
  --shade-simple-view-height-md: 22.2%;
  --shade-simple-view-p: var(--offset-24) var(--offset-20);
}

@media (max-width: 1280px) {
  .brandbook-module {
    --shade-simple-view-width: 14rem;
  }
}

@media (max-width: 1024px) {
  .brandbook-module {
    --shade-simple-view-width: 10rem;
    --shade-simple-view-p: var(--offset-16) var(--offset-24);
  }
}

@media (max-width: 768px) {
  .brandbook-module {
    --shade-simple-view-p: var(--offset-4) var(--offset-8);
    --shade-simple-view-width: 8rem;
  }
}
@media (max-width: 576px) {
  .brandbook-module {
    --shade-simple-view-p: var(--offset-4) var(--offset-8);
    --shade-simple-view-width: 2.5rem;
    --shade-simple-view-height-md: inherit;
    --shade-simple-view-height-sm: inherit;
  }
}
