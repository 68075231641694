.offer-module {
  --timeline-size: 5rem; /*80px*/
  --timeline-ff: var(--body-font-family);
  --timeline-fs: 2rem; /*32px*/
  --timeline-lh: 2.5rem; /*40px*/

  /* BEFORE AFTER LINES */
  --timeline-before-height: 0.25rem; /*4px*/
  --timeline-before-width: 100%;
  --timeline-after-height: 0.25rem; /*4px*/
  --timeline-after-width: 100%;
  --timeline-before-display: block;

  /* TIMELINE FLEX */
  --timeline-flex-direction: row;
  --timeline-item-flex-direction: column;
  --timeline-indicator-flex-direction: row;
  --timeline-text-flex-direction: column;
  --timeline-indicator-align-items: center;
}

@media (max-width: 1200px) {
  .offer-module {
    --timeline-size: 4.25rem; /*68px*/
    --timeline-fs: 1.75rem; /*28px*/
    --timeline-lh: 2.25rem; /*36px*/

    --timeline-before-height: 100%;
    --timeline-before-width: 0.25rem; /*4px*/
    --timeline-after-height: 100%;
    --timeline-after-width: 0.25rem; /*4px*/

    --timeline-flex-direction: column;
    --timeline-item-flex-direction: row;
    --timeline-indicator-flex-direction: column;
    --timeline-indicator-align-items: center;
    --timeline-text-flex-direction: row;

    --timeline-before-display: none;
  }
}

@media (max-width: 576px) {
  .offer-module {
    --timeline-size: 2.5rem; /*44px*/
    --timeline-fs: 1.125rem; /*18px*/
    --timeline-lh: 1.625rem; /*26px*/
  }
}
