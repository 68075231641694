.brandbook-module {
  /* Font size */
  --big-typo-h1-fs: 3.75rem;
  --big-typo-h2-fs: 3rem;
  --big-typo-h3-fs: 2.5rem;
  --big-typo-h4-fs: 2rem;
  --big-typo-h5-fs: 1.5rem;
  /* Line height */
  --big-typo-h1-lh: 4.5rem;
  --big-typo-h2-lh: 3.5rem;
  --big-typo-h3-lh: 3rem;
  --big-typo-h4-lh: 2.5rem;
  --big-typo-h5-lh: 2rem;

  /* Font weight */
  --big-typo-fw-semibold: 600;
}
