.offer-module {
  /* SCREEN */
  --screen-bg-default: var(--bg-primary);
  --screen-min-height-default: 100vh;
  --screen-padding-default: var(--p-xl);
  --screen-box-sizing-default: border-box;
  --screen-header-max-height: 8.625rem;
  --screen-footer-max-height: 8.625rem;
  --screen-content-min-height: calc(
    100vh - var(--screen-header-max-height) - var(--screen-footer-max-height)
  );

  /* HEADER */
  --header-offset: var(--offset-48);

  /* FOOTER */
  --footer-offset: var(--offset-48);

  /* decoration of edge lines */
  --screen-decoration-width: 2rem;
}
@media (max-width: 1200px) {
  .offer-module {
    --screen-decoration-width: 1.5rem;
    --screen-padding-default: var(--p-lg);
    --header-offset: var(--offset-32);
    --footer-offset: var(--offset-32);
  }
}
@media (max-width: 576px) {
  .offer-module {
    --screen-decoration-width: 1.25rem;
    --screen-padding-default: var(--p-md);
    --header-offset: var(--offset-24);
    --footer-offset: var(--offset-24);
  }
}
