.brandbook-module {
  --avatar-screen-top: 33%;
  --avatar-screen-right: -6%;
  --avatar-grid-column: 3;
  --avatar-grid-width: 16vw;
}

@media (max-width: 1280px) {
  .brandbook-module {
    --avatar-grid-column: 3;
    --avatar-grid-width: 20vw;
  }
}

@media (max-width: 768px) {
  .brandbook-module {
    --avatar-grid-column: 3;
    --avatar-grid-width: 35vw;
  }
}

@media (max-width: 576px) {
  .brandbook-module {
    --avatar-grid-column: 1;
    --avatar-grid-width: 40vw;
  }
}
