.brandbook-module {
  --color-simple-view-border: var(--bw-1) var(--b-solid)
    var(--color-simple-view-bc-default);

  /* offsets */
  --color-simple-view-p: var(--offset-32) var(--offset-40);

  /* sm */
  --color-simple-view-width-sm: 26rem;
  --color-simple-view-height-sm: 85%;

  /* md */
  --color-simple-view-width-md: 24rem;
  --color-simple-view-height-md: 100%;

  /* xl */
  --color-simple-view-width-xl: 37rem;
  --color-simple-view-height-xl: 40%;
}

@media (max-width: 1440px) {
  .brandbook-module {
    /* sm */
    --color-simple-view-width-sm: 26rem;

    /* md */
    --color-simple-view-width-md: 24rem;

    /* xl */
    --color-simple-view-width-xl: 37rem;
  }
}

@media (max-width: 1280px) {
  .brandbook-module {
    /* sm */
    --color-simple-view-width-sm: 24rem;

    /* md */
    --color-simple-view-width-md: 22rem;

    /* xl */
    --color-simple-view-width-xl: 35rem;
  }
}

@media (max-width: 1024px) {
  .brandbook-module {
    /* sm */
    --color-simple-view-width-sm: 20rem;

    /* md */
    --color-simple-view-width-md: 18rem;

    /* xl */
    --color-simple-view-width-xl: 26rem;
  }
}

@media (max-width: 768px) {
  .brandbook-module {
    /* offsets */
    --color-simple-view-p: var(--offset-24) var(--offset-32);

    /* sm */
    --color-simple-view-width-sm: 16rem;

    /* md */
    --color-simple-view-width-md: 14rem;

    /* xl */
    --color-simple-view-width-xl: 21.5rem;
  }
}

@media (max-width: 576px) {
  .brandbook-module {
    /* offsets */
    --color-simple-view-p: var(--offset-12) var(--offset-16);

    /* sm */
    --color-simple-view-width-sm: 10.5rem;

    /* md */
    --color-simple-view-width-md: 9rem;

    /* xl */
    --color-simple-view-width-xl: 16rem;
  }
}
