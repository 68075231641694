.offer-module {
    --technology-image-size: 216px
}
@media (max-width: 1024px) {
    .offer-module{
        --technology-image-size: 180px;
    }
}
@media (max-width: 425px) {
    .offer-module{
        --technology-image-size: 90px;
    }
}
