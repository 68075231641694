
@media (max-width: 1920px), (min-width: 1920px) {
    .brandbook-module {
        --app-icon-right: 0%;
        --app-icon-bottom: 0%;

    }
}

@media (max-width: 1280px) {
    .brandbook-module {
        --app-icon-bottom: 6%;
        --app-icon-right: 3%;
    }
}

@media (max-width: 768px) {
    .brandbook-module {
        --app-icon-right: 8%;
        --app-icon-bottom: 32%;
    }
}

@media (max-width: 576px) {
    .brandbook-module {
        --app-icon-right: 14%;
        --app-icon-bottom: 42%;

    }
}