.brandbook-module {
  --text-preview-display: flex;
  --text-preview-mw: 500px;
}

@media (max-width: 1280px) {
  .brandbook-module {
    --text-preview-mw: 360px;
  }
}
@media (max-width: 1024px) {
  .brandbook-module {
    --text-preview-display: none;
  }
}
