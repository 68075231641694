.offer-module {
  /* Colors */
  /* Accent */
  --accent: #2db867;
  --accent-darken: #228a4d;

  /* Transparent */
  --accent-lighten-t1: #2db867bf;
  --accent-lighten-t2: #2db86780;
  --accent-lighten-t3: #2db86740;
  --accent-lighten-t4: #2db86726;
  --accent-lighten-t5: #2db8671a;
  --accent-lighten-t6: #2db8670d;

  /* Neutral (Dark) */
  --neutral: #253037;
  --neutral-darken: #0f1316;

  /* Transparent */
  --neutral-lighten-t1: #253037bf;
  --neutral-lighten-t2: #25303780;
  --neutral-lighten-t3: #25303740;
  --neutral-lighten-t4: #25303726;
  --neutral-lighten-t5: #2530371a;
  --neutral-lighten-t6: #2530370d;

  /* Success */
  --success-darken: #15803d;
  --success: #22c55e;
  --success-lighten: #86efac;

  /* Warning */
  --warning-darken: #c2410c;
  --warning: #f97316;
  --warning-lighten: #fdba74;

  /* Danger */
  --danger-darken: #b91c1c;
  --danger: #ef4444;
  --danger-lighten: #fca5a5;

  /* Gray */
  --gray-darken: #3f3f46;
  --gray: #808080;
  --gray-lighten1: #b3b3b3;
  --gray-lighten2: #e6e6e6;

  /* Singletons */
  --black: #000000;
  --white: #ffffff;

  /* BODY */
  --body-bg: var(--white);

  /* TYPOGRAPHY */
  --title-color-primary: var(--neutral);
  --title-color-secondary: var(--gray);
  --title-color-white: var(--white);
  --title-color-success: var(--success);
  --title-color-accent: var(--accent);
  --title-color-warning: var(--warning);
  --title-color-danger: var(--danger);

  --text-color-primary: var(--neutral);
  --text-color-accent: var(--accent);
  --text-color-secondary: var(--gray);
  --text-color-white: var(--white);
  --text-color-success: var(--success);
  --text-color-warning: var(--warning);
  --text-color-danger: var(--danger);
  --text-color-default: var(--neutral);

  /* BACKGROUNDS */
  --bg-primary: var(--white);

  /* ACTIONS */
  --action-primary-default-border: var(--gray-lighten2);
  --action-primary-hover-border: var(--accent-lighten-t1);
  --action-primary-selected-border: var(--accent);
  --action-accent-color: var(--accent);
  --action-primary-bg: var(--black);
  --action-primary-hover-bg: var(--gray-darken);
  --action-primary-color: var(--white);
  --action-primary-hover-color: var(--white);
  --action-secondary-color: var(--gray-darken);
  --action-secondary-default-border: var(--gray-lighten2);
  --action-secondary-hover-border: var(--black);
  --action-secondary-hover-color: var(--white);
  --action-secondary-hover-bg: var(--black);

  --action-accent-hover-bg: var(--accent-darken);
  --action-accent-hover-color: var(--white);
  --action-accent-bg: var(--accent);

  --action-success-hover-color: var(--white);
  --action-success-hover-bg: var(--success-darken);
  --action-success-bg: var(--success);

  --action-warning-hover-color: var(--white);
  --action-warning-hover-bg: var(--warning-darken);
  --action-warning-bg: var(--warning);

  --action-neutral-hover-color: var(--white);
  --action-neutral-hover-bg: var(--neutral-darken);
  --action-neutral-bg: var(--neutral);

  --action-warning-hover-color: var(--white);
  --action-warning-hover-bg: var(--warning-darken);
  --action-warning-bg: var(--warning);

  --action-danger-hover-color: var(--white);
  --action-danger-hover-bg: var(--danger-darken);
  --action-danger-bg: var(--danger);

  --action-gray-hover-color: var(--white);
  --action-gray-hover-bg: var(--gray-darken);
  --action-gray-bg: var(--gray);

  --action-black-hover-color: var(--black);
  --action-black-hover-bg: var(--white);
  --action-black-bg: var(--black);

  --action-white-hover-color: var(--white);
  --action-white-hover-bg: var(--black);
  --action-white-bg: var(--white);
  --action-white-color: var(--black);

  --action-gray-default-bg: var(--gray-lighten2);

  /* BUTTONS */
  --btn-default-bg: var(--action-primary-bg);
  --btn-default-hover-bg: var(--action-primary-hover-bg);
  --btn-default-color: var(--action-primary-color);
  --btn-default-hover-color: var(--action-primary-hover-color);

  --btn-primary-bg: var(--action-primary-bg);
  --btn-primary-hover-bg: var(--action-primary-hover-bg);
  --btn-primary-color: var(--action-primary-color);
  --btn-primary-hover-color: var(--action-primary-hover-color);

  --btn-accent-bg: var(--action-accent-bg);
  --btn-accent-hover-bg: var(--action-accent-hover-bg);
  --btn-accent-hover-color: var(--action-accent-hover-color);

  --btn-neutral-bg: var(--action-neutral-bg);
  --btn-neutral-hover-bg: var(--action-neutral-hover-bg);
  --btn-neutral-hover-color: var(--action-neutral-hover-color);

  --btn-success-bg: var(--action-success-bg);
  --btn-success-hover-bg: var(--action-success-hover-bg);
  --btn-success-hover-color: var(--action-success-hover-color);

  --btn-warning-bg: var(--action-warning-bg);
  --btn-warning-hover-bg: var(--action-warning-hover-bg);
  --btn-warning-color: var(--action-warning-color);
  --btn-warning-hover-color: var(--action-warning-hover-color);

  --btn-danger-bg: var(--action-danger-bg);
  --btn-danger-hover-bg: var(--action-danger-hover-bg);
  --btn-danger-hover-color: var(--action-danger-hover-color);

  --btn-gray-bg: var(--action-gray-bg);
  --btn-gray-hover-bg: var(--action-gray-hover-bg);
  --btn-gray-hover-color: var(--action-gray-hover-color);

  --btn-black-bg: var(--action-black-bg);
  --btn-black-hover-bg: var(--action-black-hover-bg);
  --btn-black-hover-color: var(--action-black-hover-color);

  --btn-white-bg: var(--action-white-bg);
  --btn-white-hover-bg: var(--action-white-hover-bg);
  --btn-white-color: var(--action-white-color);
  --btn-white-hover-color: var(--action-white-hover-color);

  --btn-text-color: var(--action-accent-color);
  --btn-text-hover-color: var(--action-accent-color);
  --btn-text-hover-bg: var(--action-gray-default-bg);

  --btn-link-hover-color: var(--action-accent-color);
  --btn-link-color: var(--action-black-hover-color);
  --btn-link-bg: var(--action-gray-default-bg);

  --btn-outlined-bg: transparent;
  --btn-outlined-color: var(--action-secondary-color);
  --btn-outlined-border-color: var(--action-secondary-default-border);
  --btn-outlined-hover-border-color: var(--action-secondary-hover-border);
  --btn-outlined-hover-color: var(--action-secondary-hover-color);
  --btn-outlined-hover-bg: var(--action-secondary-hover-bg);

  /* OPTIONS */
  --option-default-selected-background: var(--bg-primary);
  --option-default-hover-background: var(--bg-primary);
  --option-default-selected-color: var(--white);
  --option-default-hover-color: var(--white);
  --option-default-border: 1px solid var(--action-primary-default-border);
  --option-default-hover-border: 1px solid var(--action-primary-hover-border);
  --option-default-selected-border: 1px solid
    var(--action-primary-selected-border);

  /* SCREEN */
  --screen-bg-default: var(--bg-white);

  /* TABLE */
  --table-bg-default: var(--bg-primary);
  --table-head-bg-default: var(--neutral);
  --table-head-font-family: var(--title-font-family);
  --table-head-font-weight: var(--fw-bold);
  --table-content-font-family: var(--body-font-family);
  --table-content-font-weight: var(--fw-medium);
  --table-content-color: var(--text-color-white);
  --table-content-height: auto;
  --table-model-min-width: 360px;
  --table-row-border-default: 1px solid var(--gray-lighten2);
  --table-shadow-default: 0px 0px 0px 2px var(--table-head-bg-default);
  --table-text-font-size: var(--h5-fs);
  --table-text-line-height: 1.75rem;

  /* PADDING */
  --p-none: 0px;
  --p-xs: 0.125rem;
  --p-sm: 0.25rem;
  --p-md: 0.5rem;
  --p-lg: 1rem;

  --p-xl: 2rem;
  --p-xxl: 4rem;

  /* MARGIN */
  --m-none: 0px;
  --m-xs: 0.125rem;
  --m-sm: 0.25rem;
  --m-md: 0.5rem;
  --m-lg: 1rem;
  --m-xl: 2rem;
  --m-xxl: 4rem;

  /* LIST_MARKER */
  --list-marker-default: var(--neutral);
  --list-marker-accent: var(--accent);

  --p-xl: 1.5rem;
  --p-xxl: 2rem;
  --p-xxxl: 2.5rem;
}
