.brandbook-module {
  --switcher-screen-top: 42%;
}

@media (min-width: 1920px) {
  .brandbook-module {
    --switcher-screen-right: -12%;
  }
}
@media (max-width: 1920px) {
  .brandbook-module {
    --switcher-screen-right: 8%;
  }
}
