.brandbook-module {
  --avatar-size-lg: 3rem;
  --avatar-size-md: 2.5rem;
  --avatar-size-sm: 2rem;
  --avatar-size-xs: 1.5rem;
  --avatar-fw: var(--title-fw-bold);

  --avatar-ff: var(--ff-primary);

  --avatar-br: var(--br-full);
  --avatar-outline-offset: var(--offset-4);

  --avatar-fs-lg: var(--text-body1-fs);
  --avatar-lh-lg: var(--text-body1-lh);

  --avatar-fs-md: var(--text-body1-fs);
  --avatar-lh-md: var(--text-body1-lh);

  --avatar-fs-sm: var(--text-body2-fs);
  --avatar-lh-sm: var(--text-body2-lh);

  --avatar-fs-xs: var(--text-caption1-fs);
  --avatar-lh-xs: var(--text-caption1-lh);

  --avatar-transform-scale: scale(10);
}

@media (max-width: 1280px) {
  .brandbook-module {
    --avatar-transform-scale: scale(7);
  }
}

@media (max-width: 768px) {
  .brandbook-module {
    --avatar-transform-scale: scale(5);
  }
}

@media (max-width: 576px) {
  .brandbook-module {
    --avatar-transform-scale: scale(3);

  }
}
