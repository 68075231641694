.brandbook-module {
  --decoration-opening-scale: none;
  --decoration-opening-left: 22%;
  --decoration-opening-top: 4%;
  --decoration-opening-z: -1;

  --decoration-closing-scale: scale(0.9);
  --decoration-closing-top: 0%;
  --decoration-closing-z: -1;

  --img-visibility: visible;
}

@media (min-width: 1920px) {
  .brandbook-module {
    --decoration-closing-left: 16%;
    --decoration-closing-top: 8%;
  }
}

@media (max-width: 1920px) {
  .brandbook-module {
    --decoration-closing-left: 14%;
    --decoration-closing-top: 10%;
  }
}

@media (max-width: 1440px) {
  .brandbook-module {
    --decoration-closing-left: 2%;
    --decoration-closing-top: 12%;
  }
}

@media (max-width: 1280px) {
  .brandbook-module {
    --decoration-opening-left: 24%;
    --decoration-opening-top: 8%;
  }
}

@media (max-width: 1024px) {
  .brandbook-module {
    --decoration-opening-scale: scale(0.9);
    --decoration-opening-left: -10%;
    --decoration-opening-top: 12%;
    --decoration-opening-z: -1;

    --decoration-closing-scale: scale(0.5);
    --decoration-closing-left: -35%;
  }
}

@media (max-width: 768px) {
  .brandbook-module {
    --decoration-opening-scale: scale(0.8);
    --decoration-opening-left: -20%;
    --decoration-opening-top: 14%;

    --decoration-closing-scale: scale(0.8);
    --decoration-closing-left: -4%;
    --decoration-closing-top: 18%;
  }
}
@media (max-width: 576px) {
  .brandbook-module {
    --decoration-opening-scale: scale(0.6);
    --decoration-opening-left: -76%;
    --decoration-opening-top: 6%;

    --decoration-closing-scale: scale(0.9);
    --decoration-closing-left: -14%;
    --decoration-closing-top: 20%;
  }
}
