.offer-module {
  --decoration-img-max-width: 150%;
  --decoration-img-b: 0;
  --decoration-img-l: 15%;
}
@media (max-width: 768px) {
  .offer-module {
    --decoration-img-max-width: 160%;
    --decoration-img-b: 14%;
    --decoration-img-l: 6%;
  }
}

@media (max-width: 576px) {
  .offer-module {
    --decoration-img-max-width: 280%;
    --decoration-img-l: 12%;
    --decoration-img-b: 15%;
  }
}
