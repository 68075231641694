.brandbook-module {
  --logo-border: var(--bw-1) var(--b-solid) var(--logo-bc-default);

  /* Size */
  /* sm */
  --logo-width-sm: 18rem;

  /* md */
  --logo-width-md: 24rem;
  --logo-height-md: 24rem;

  /* xl */
  --logo-width-xl: 37rem;

  /* xxl */
  --logo-width-xxl: 37rem;
  --logo-height-xxl: 24rem;
}

@media (max-width: 1280px) {
  .brandbook-module {
    /* sm */
    --logo-width-sm: 17rem;

    /* md */
    --logo-width-md: 20rem;
    --logo-height-md: 20rem;

    /* xl */
    --logo-width-xl: 35rem;

    /* xxl */
    --logo-width-xxl: 35rem;
    --logo-height-xxl: 16rem;
  }
}

@media (max-width: 1024px) {
  .brandbook-module {
    /* sm */
    --logo-width-sm: 14rem;

    /* md */
    --logo-width-md: 15rem;
    --logo-height-md: 15rem;

    /* xl */
    --logo-width-xl: 24rem;

    /* xxl */
    --logo-width-xxl: 24rem;
    --logo-height-xxl: 10rem;
  }
}

@media (max-width: 768px) {
  .brandbook-module {
    /* sm */
    --logo-width-sm: 12rem;

    /* md */
    --logo-width-md: 12rem;
    --logo-height-md: 12rem;

    /* xl */
    --logo-width-xl: 22rem;

    /* xxl */
    --logo-width-xxl: 22rem;
    --logo-height-xxl: 10rem;
  }
}

@media (max-width: 576px) {
  .brandbook-module {
    /* sm */
    --logo-width-sm: 8.5rem;

    /* md */
    --logo-width-md: 12rem;
    --logo-height-md: 12rem;

    /* xl */
    --logo-width-xl: 18rem;

    /* xxl */
    --logo-width-xxl: 18rem;
    --logo-height-xxl: 8rem;
  }
}
