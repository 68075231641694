@font-face {
  font-family: 'GalanoGrotesque';
  src: url('./GalanoGrotesqueLight.ttf');
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: 'GalanoGrotesque';
  src: url('./GalanoGrotesqueRegular.ttf');
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'GalanoGrotesque';
  src: url('./GalanoGrotesqueMedium.ttf');
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: 'GalanoGrotesque';
  src: url('./GalanoGrotesqueSemiBold.ttf');
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'GalanoGrotesque';
  src: url('./GalanoGrotesqueBold.ttf');
  font-weight: 700;
  font-display: swap;
}
