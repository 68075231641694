.brandbook-module {
  --icon-advanced-view-top: 16%;
  --icon-advanced-view-left: 16.2%;
}

.icon-greed {
  width: 37rem;
  height: 37rem;
}
.icon-union {
  width: 25rem;
  height: 25rem;
}

@media (max-width: 1440px) {
  .icon-greed {
    width: 34rem;
    height: 34rem;
  }
  .icon-union {
    width: 23rem;
    height: 23rem;
  }
}
@media (max-width: 1280px) {
  .icon-greed {
    width: 28rem;
    height: 28rem;
  }
  .icon-union {
    width: 18.9rem;
    height: 18.9rem;
  }
}
@media (max-width: 1024px) {
  .icon-greed {
    width: 24rem;
    height: 24rem;
  }
  .icon-union {
    width: 16.2rem;
    height: 16.2rem;
  }
}
@media (max-width: 576px) {
  .icon-greed {
    width: 20rem;
    height: 20rem;
  }
  .icon-union {
    width: 13.5rem;
    height: 13.5rem;
  }
}
