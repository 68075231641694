.brandbook-module {
  /* Alfa */
  --alpha-default: 100%;
  --alpha-1: 72%;
  --alpha-2: 40%;
  --alpha-3: 32%;
  --alpha-4: 20%;
  --alpha-5: 10%;
  --alpha-6: 5%;

  /* Accent */
  --accent: 45, 184, 103;
  --senseteq-accent: 45, 184, 103;

  /* Darken */
  --accent-darken: 38, 142, 82;

  --accent-default: rgba(var(--accent), var(--alpha-default)); /* 100% */
  --accent-darken-default: rgba(
    var(--accent-darken),
    var(--alpha-default)
  ); /* 100% */

  /* Transparent */
  --accent-t-lighten1: rgba(var(--accent), var(--alpha-1)); /* 72% */
  --accent-t-lighten2: rgba(var(--accent), var(--alpha-2)); /* 40% */
  --accent-t-lighten3: rgba(var(--accent), var(--alpha-3)); /* 32% */
  --accent-t-lighten4: rgba(var(--accent), var(--alpha-4)); /* 20% */
  --accent-t-lighten5: rgba(var(--accent), var(--alpha-5)); /* 10% */
  --accent-t-lighten6: rgba(var(--accent), var(--alpha-6)); /* 5% */

  /* Neutral (Dark) */
  --neutral: 37, 48, 55;
  --neutral-default: rgba(var(--neutral), var(--alpha-default)); /* 100% */

  /* Transparent */
  --neutral-t-lighten1: rgba(var(--neutral), var(--alpha-1)); /* 72% */
  --neutral-t-lighten2: rgba(var(--neutral), var(--alpha-2)); /* 40% */
  --neutral-t-lighten3: rgba(var(--neutral), var(--alpha-3)); /* 32% */
  --neutral-t-lighten4: rgba(var(--neutral), var(--alpha-4)); /* 20% */
  --neutral-t-lighten5: rgba(var(--neutral), var(--alpha-5)); /* 10% */
  --neutral-t-lighten6: rgba(var(--neutral), var(--alpha-6)); /* 5% */

  /* White */
  --white: 255, 255, 255;
  --white-default: rgba(var(--white), var(--alpha-default)); /* 100% */
  /* Transparent */

  /* radio-button */
  --radio-bc-default: var(--action-primary-b);
  --radio-bc-disable: var(--action-disabled-b);
  --radio-bc-focus: var(--action-primary-b-focus);
  --radio-bc-active: var(--action-primary-b-active);
  --radio-bc-hover: var(--action-primary-b-hover);

  /* text */
  --radio-c-disable: var(--action-disabled-c);
  --radio-c-default: var(--action-secondary-c);

  --radio-bg-default: var(--action-white-bg);
  --radio-bg-disable: var(--action-disabled-bg);

  /* Typography */
  --alpha-default: 100%;

  --accent: 45, 184, 103;
  --accent-default: rgba(var(--accent), var(--alpha-default));

  --white-t-lighten1: rgba(var(--white), var(--alpha-1)); /* 72% */
  --white-t-lighten2: rgba(var(--white), var(--alpha-2)); /* 40% */
  --white-t-lighten3: rgba(var(--white), var(--alpha-3)); /* 32% */
  --white-t-lighten4: rgba(var(--white), var(--alpha-4)); /* 20% */
  --white-t-lighten5: rgba(var(--white), var(--alpha-5)); /* 10% */
  --white-t-lighten6: rgba(var(--white), var(--alpha-6)); /* 5% */

  /* Black */
  --black: 0, 0, 0;
  --black-default: rgba(var(--black), var(--alpha-default)); /* 100% */
  /* Transparent */
  --black-t-lighten5: rgba(var(--black), var(--alpha-5)); /* 10% */

  /* inverse */
  --neutral-inverse: var(--white-t-lighten1);
  --white-inverse: var(--neutral-t-lighten1);

  /* MEDIATORS */
  /* ACTIONS */
  /* Backgrounds */
  --action-primary-bg: var(--accent-default);
  --action-primary-bg-hover: var(--accent-t-lighten1);
  --action-primary-bg-active: var(--accent-darken-default);
  --action-primary-bg-focus: var(--action-primary-bg-hover);
  --action-primary-bg-disabled: var(--accent-t-lighten2);

  --action-secondary-bg: var(--neutral-t-lighten4);
  --action-secondary-bg-hover: var(--neutral-t-lighten5);
  --action-secondary-bg-active: var(--neutral-t-lighten3);
  --action-secondary-bg-disabled: var(--neutral-t-lighten6);

  --action-white-bg: var(--white-default);
  --action-disabled-bg: var(--action-secondary-bg-hover);

  /* Color */
  --action-primary-c: var(--white-default);
  --action-secondary-c: var(--neutral-default);
  --action-disabled-c: var(--neutral-t-lighten3);

  /* Border */
  --action-primary-bc: var(--neutral-t-lighten3);
  --action-primary-bc-hover: var(--accent-t-lighten1);
  --action-primary-bc-active: var(--accent-darken-default);
  --action-primary-bc-focus: var(--accent-default);
  --action-primary-bc-disabled: var(--neutral-t-lighten4);
  --action-primary-bc-selected: var(--accent-default);

  /* BACKGROUND */
  /* Accent */
  --accent-bg: var(--accent-default);

  /* Dark */
  --dark-bg: var(--neutral-default);
  --dark-t-lighten4-bg: var(--neutral-t-lighten4);
  --dark-t-lighten5-bg: var(--neutral-t-lighten5);
  --dark-t-lighten6-bg: var(--neutral-t-lighten6);

  /* White */
  --white-bg: var(--white-default);

  /* BORDER */
  --bc-accent: var(--accent-default);

  --bc-accent-dark: var(--accent-darken-default);

  --bc-primary: var(--neutral-default);
  --bc-primary-lighten3: var(--neutral-t-lighten3);
  --bc-primary-lighten4: var(--neutral-t-lighten4);
  --bc-primary-lighten5: var(--neutral-t-lighten5);
  --bc-primary-lighten6: var(--neutral-t-lighten6);

  --bc-white: var(--white-default);

  --bc-primary-inverse: var(--white-default);
  --bc-primary-lighten4-inverse: var(--white-t-lighten4);

  /* TEXT/ICON */
  --text-primary: var(--neutral-default);
  --text-secondary: var(--neutral-t-lighten1);
  --text-placeholder: var(--neutral-t-lighten1);
  --text-disabled: var(--neutral-t-lighten2);

  --text-primary-inverse: var(--white-default);
  --text-secondary-inverse: var(--white-t-lighten1);

  --text-accent: var(--accent-default);
  --text-white: var(--white-default);

  /* ELEMENTS */
  /* Screens */
  --content-screen-bg: var(--white-bg);
  --exterior-screen-bg: var(--white-bg);
  --intro-screen-bg: var(--dark-bg);

  /* Button */
  --btn-primary-text-c: var(--white-default);
  --btn-secondary-text-c: var(--text-primary);
  --btn-disabled-text-c: var(--text-disabled);

  --btn-primary-default: var(--action-primary-bg);
  --btn-secondary-default: var(--action-secondary-bg);
  --btn-disabled-default: var(--action-secondary-bg-hover);

  --btn-primary-hover: var(--action-primary-bg-hover);
  --btn-primary-active: var(--action-primary-bg-active);
  --btn-secondary-hover: var(--action-secondary-bg-hover);
  --btn-secondary-active: var(--action-secondary-bg-active);
  --btn-disabled-hover: var(--action-disabled-bg);
  --btn-disabled-active: var(--action-disabled-bg);

  /* Divider */
  --divider-white-bg: var(--white-bg);
  --divider-accent-bg: var(--accent-bg);
  --divider-dark-bg: var(--dark-bg);
  --divider-senseteq-bg: rgba(var(--senseteq-accent), var(--alpha-default));

  /* Input */
  --input-bg: var(--dark-t-lighten5-bg);
  --input-placeholder-c-default: var(--text-placeholder);
  --input-placeholder-c-disabled: var(--text-disabled);
  --input-label-c: var(--text-primary);

  --input-default-bc: unset;
  --input-hover-bc: var(--bc-primary-lighten4);
  --input-active-bc: var(--bc-accent);

  /* Footer */
  --footer-c-white: var(--white-default);
  --footer-c-dark: var(--text-primary);

  /* Header */
  --header-text-white: var(--white-default);
  --header-text-default: var(--text-primary);

  /* Shades */
  --shades-list-bg: var(--white-bg);
  --shades-list-bg-dark: var(--dark-bg);

  /* AppIcon */
  --app-icon-bg: var(--dark-bg);

  /* Switcher */
  --switcher-bg-default: var(--action-secondary-bg);
  --switcher-bg-active: var(--action-primary-bg);
  --switcher-slider-bg: var(--white-bg);

  /* Checkbox Background */
  --checkbox-checked-bg-default: var(--action-primary-bg);
  --checkbox-checked-bg-hover: var(--action-primary-bg-hover);
  --checkbox-checked-bg-active: var(--action-primary-bg-active);
  --checkbox-checked-bg-focus: var(--action-primary-bg-focus);
  --checkbox-checked-bg-disabled: var(--action-primary-bg-disabled);

  --checkbox-bg-disabled: var(--action-secondary-bg-disabled);

  /* Checkbox Border Color */
  --checkbox-bc-default: var(--action-primary-bc);
  --checkbox-bc-hover: var(--action-primary-bc-hover);
  --checkbox-bc-active: var(--action-primary-bc-active);
  --checkbox-bc-focus: var(--action-primary-bc-focus);
  --checkbox-bc-disabled: var(--action-primary-bc-disabled);

  /* Checkbox Color */
  --checkbox-c-disabled: var(--text-disabled);
  --checkbox-c: var(--text-primary);

  /* Avatar */
  --avatar-text-c: var(--text-secondary);

  --avatar-bg-default: var(--dark-t-lighten6-bg);
  --avatar-bg-hover: var(--dark-t-lighten5-bg);
  --avatar-bg-active: var(--dark-t-lighten4-bg);
  --avatar-bg-selected: var(--dark-t-lighten6-bg);

  --avatar-bs-default: 0px 0px 0px var(--bw-1) var(--bc-primary-lighten5);
  --avatar-bs-hover: 0px 0px 0px var(--bw-1) var(--bc-primary-lighten4);
  --avatar-bs-active: 0px 0px 0px var(--bw-1) var(--bc-primary-lighten3);
  --avatar-bs-selected: 0px 0px 0px var(--bw-1) var(--bc-primary-lighten5);

  --avatar-outline-default: unset;
  --avatar-outline-hover: var(--bw-2) var(--b-solid) var(--bc-primary-lighten6);
  --avatar-outline-active: var(--bw-2) var(--b-solid) var(--bc-primary-lighten5);
  --avatar-outline-selected: var(--bw-2) var(--b-solid) var(--bc-accent);

  /* Radio Button */
  --radio-bc-default: var(--action-primary-bc);
  --radio-bc-disabled: var(--action-primary-bc-disabled);
  --radio-bc-focus: var(--action-primary-bc-focus);
  --radio-bc-active: var(--action-primary-bc-active);
  --radio-bc-hover: var(--action-primary-bc-hover);
  --radio-bc-selected: var(--action-primary-bc-selected);

  --radio-c-disabled: var(--action-disabled-c);
  --radio-c-default: var(--action-secondary-c);
  --radio-bg-default: var(--action-white-bg);
  --radio-bg-disabled: var(--action-disabled-bg);

  /* Logo */
  --logo-bc-default: var(--bc-primary-lighten4);
  --logo-accent-bg: var(--accent-bg);
  --logo-white-bg: var(--white-bg);
  --logo-dark-bg: var(--dark-bg);

  /* Switcher */
  --switcher-bg-default: var(--action-secondary-bg);
  --switcher-bg-active: var(--action-primary-bg);
  --switcher-slider-bg: var(--white-bg);

  /* Color simple view */
  --color-simple-view-accent-bg: var(--accent-bg);
  --color-simple-view-white-bg: var(--white-bg);
  --color-simple-view-dark-bg: var(--dark-bg);
  --color-simple-view-bc-default: var(--bc-primary-lighten4);
}
