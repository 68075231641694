.brandbook-module {
  /* Size */
  --logo-list-max-w: 37rem;
}

@media (max-width: 1280px) {
  .brandbook-module {
    /* Size */
    --logo-list-max-w: 35rem;
  }
}

@media (max-width: 1024px) {
  .brandbook-module {
    /* Size */
    --logo-list-max-w: 29rem;
  }
}

@media (max-width: 768px) {
  .brandbook-module {
    /* Size */
    --logo-list-max-w: 25rem;
  }
}

@media (max-width: 576px) {
  .brandbook-module {
    /* Size */
    --logo-list-max-w: 18rem;
  }
}
