@media (max-width: 1024px) {
  .brandbook-module {
    --aside-mb: 180px;
  }
}
@media (max-width: 576px) {
  .brandbook-module {
    --aside-mb: 150px;
  }
}
