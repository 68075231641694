.brandbook-module {
  --android-logo-right: 0%;
}
@media (max-width: 1280px) {
  .brandbook-module {
    --android-logo-width: 666px;
    --android-logo-height: 890px;
  }
}
@media (max-width: 1024px) {
  .brandbook-module {
    --android-logo-width: 640px;
    --android-logo-height: 858px;
  }
}

@media (max-width: 768px) {
  .brandbook-module {
    --android-logo-width: 620px;
    --android-logo-height: 816px;

    --android-logo-right: -6%;
  }
}

@media (max-width: 576px) {
  .brandbook-module {
    --android-logo-width: 370px;
    --android-logo-height: 816px;

    --android-logo-right: -1%;
  }
}
