.brandbook-module {
  /* positioned container  */
  --ios-logo-right: 0%;
  --ios-logo-top: -12%;
  --ios-logo-height: 1024px;
}
@media (max-width: 1280px) {
  .brandbook-module {
    --ios-logo-width: 606px;
    --ios-logo-height: 1148px;
  }
}

@media (max-width: 768px) {
  .brandbook-module {
    --ios-logo-width: 684px;
    --ios-logo-height: 1237px;

    /* positioned container  */
    --ios-logo-right: -20%;
    --ios-logo-top: -12%;
  }
}

@media (max-width: 576px) {
  .brandbook-module {
    --ios-logo-width: 346px;
    --ios-logo-height: 810px;

    /* positioned container  */
    --ios-logo-right: -20%;
    --ios-logo-top: 0;
  }
}
