.brandbook-module {
  /* Height */
  --divider-xs-height: 96px;
  --divider-sm-height: 128px;
  --divider-md-height: 144px;
  --divider-xl-height: 200px;

  /* width */
  --divider-width: 2px;
  --divider-semibold-width: 4px;
}

@media (max-width: 576px) {
  .brandbook-module {
    --divider-xs-height: 64px;
    --divider-sm-height: 96px;
    --divider-md-height: 128px;
    --divider-xl-height: 144px;
  }
}
