@font-face {
  font-family: 'PlusJakartaSans';
  src: url('./PlusJakartaSans-Medium.ttf');
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'PlusJakartaSans';
  src: url('./PlusJakartaSans-SemiBold.ttf');
  font-weight: 600;
  font-display: swap;
}
