.offer-module {
  --video-width: 100%;
  --video-height: 60vh;
}

@media (max-width: 576px) {
  .offer-module {
    --video-height: 40vh;
  }
} 
