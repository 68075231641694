.brandbook-module {
  --button-screen-bottom: -9%;
  --button-screen-right: 28%;
}

@media (max-width: 768px) {
  .brandbook-module {
    --button-screen-bottom: -6%;
    --button-screen-right: 24%;
  }
}
@media (max-width: 576px) {
  .brandbook-module {
    --button-screen-right: 6%;
    --button-screen-bottom: 4%;
  }
}
