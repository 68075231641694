.brandbook-module {
  --shade-advanced-view-width: 17.5rem;
  --shade-advanced-view-height: 22.2%;
  --shade-advanced-view-py: var(--offset-24);
  --shade-advanced-view-px: var(--offset-20);
}

@media (max-width: 1280px) {
  .brandbook-module {
    --shade-advanced-view-width: 14rem;
  }
}

@media (max-width: 1024px) {
  .brandbook-module {
    --shade-advanced-view-width: 10rem;
    --shade-advanced-view-py: var(--offset-16);
    --shade-advanced-view-px: var(--offset-24);
  }
}

@media (max-width: 768px) {
  .brandbook-module {
    --shade-advanced-view-width: 8rem;
    --shade-advanced-view-py: var(--offset-12);
    --shade-advanced-view-px: var(--offset-16);
  }
}

@media (max-width: 576px) {
  .brandbook-module {
    --shade-advanced-view-py: var(--offset-8);
    --shade-advanced-view-px: var(--offset-12);
    --shade-advanced-view-width: 6rem;
    --shade-advanced-view-height: inherit;
  }
}
