@media (max-width: 1920px), (min-width: 1920px) {
  .brandbook-module {
    --icon-examples-bottom: 14%;
    --icon-examples-right: 6%;
  }
}
@media (max-width: 1600px) {
  .brandbook-module {
    --icon-examples-right: 0;
  }
}
@media (max-width: 1440px) {
  .brandbook-module {
    --icon-examples-bottom: 24%;
  }
}
@media (max-width: 576px) {
  .brandbook-module {
    --icon-examples-bottom: 42%;
  }
}
