

@media (max-width: 1920px),(min-width: 1920px) {
    .brandbook-module {
     --app-icon-width: 592px;
    --app-icon-height: 592px;     
    }
}

@media (max-width: 1280px) {
    .brandbook-module {
        --app-icon-width: 500px;
        --app-icon-height: 500px;    
    }
}

@media (max-width: 768px) {
    .brandbook-module {
        --app-icon-width: 380px;
        --app-icon-height: 380px;   
    }
}

@media (max-width: 576px) {
    .brandbook-module {
        --app-icon-width: 250px;
        --app-icon-height: 250px;
   
    }
}


