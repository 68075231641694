.brandbook-module {
  --radio-screen-top: 42%;
}

@media (min-width: 1920px) {
  .brandbook-module {
    --radio-screen-right: -28%;
  }
}
@media (max-width: 1920px) {
  .brandbook-module {
    --radio-screen-right: -8%;
  }
}
@media (max-width: 1024px) {
  .brandbook-module {
    --radio-screen-right: -12%;
  }
}
@media (max-width: 576px) {
  .brandbook-module {
    --radio-screen-right: -6%;
  }
}
