   .brandbook-module {
       --radio-wrapper-direction: column;
       --radio-list-direction: row;
   }

   @media (max-width: 980px) {
       .brandbook-module {
           --radio-wrapper-direction: row;
           --radio-list-direction: column;
       }
   }

   @media (max-width: 768px) {
       .brandbook-module {
           --radio-wrapper-direction: row;
           --radio-list-direction: column;
       }
   }

   @media (max-width: 576px) {
       .brandbook-module {
           --radio-wrapper-direction: row;
           --radio-list-direction: column;

       }
   }