.brandbook-module {
  /* icon list */
  --icons-list-display: flex;
  --icon-guide-gap: var(--gap-16);

  --icon-width: 140px;
}
@media (max-width: 1920px), (min-width: 1920px) {
  .brandbook-module {
    /* positioned container  */
    --icon-guide-bottom: 8%;
    --icon-guide-right: 4%;

    /* icon list  */
    --icons-list-mw: 400px;

    /* icon size */
    --icon-xxl: 37rem;
    --icon-xl: 25rem;
  }
}
@media (max-width: 1440px) {
  .brandbook-module {
    /* positioned container */
    --icon-guide-bottom: 11%;
    --icon-guide-right: 0;
  }
}
@media (max-width: 1280px) {
  .brandbook-module {
    /* positioned container */
    --icon-guide-bottom: 8%;

    /* icon list */
    --icons-list-mb: 14rem;
    --icons-list-mw: 400px;
  }
}
@media (max-width: 1024px) {
  .brandbook-module {
    /* positioned container */
    --icon-guide-bottom: 15%;
    --icon-guide-right: 10%;

    /* icon list */
    --icons-list-mw: 450px;
  }
}
@media (max-width: 768px) {
  .brandbook-module {
    /* positioned container */
    --icon-guide-bottom: 12%;
    --icon-guide-gap: var(--gap-16) var(--gap-96);
    --icon-test: 20px;
    --icon-width: 90px;
  }
}
@media (max-width: 576px) {
  .brandbook-module {
    /* positioned container */
    --icon-guide-bottom: 14%;
    --icon-guide-right: 6%;
    --icon-guide-mr: 20px;
    --icons-list-mb: 10rem;
    --icon-guide-gap: var(--gap-16);

    --icon-width: 140px;
  }
}
