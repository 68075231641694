.offer-module {
  --timeline-p: var(--offset-4);
  --timeline-ml: var(--offset-0);
  --timeline-mb: var(--offset-16);
}
@media (max-width: 1280px) {
  .offer-module {
    --timeline-p: var(--offset-0) var(--offset-8) var(--offset-16)
      var(--offset-8);
    --timeline-ml: var(--offset-16);
    --timeline-mb: var(--offset-8);
  }
}
@media (max-width: 576px) {
  .offer-module {
    --timeline-p: var(--offset-0) var(--offset-0) var(--offset-8)
      var(--offset-0);
    --timeline-ml: var(--offset-16);
    --timeline-mb: var(--offset-4);
  }
}
