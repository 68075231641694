.offer-module {
  --btn-font-family: var(--body-font-family);
  --btn-border-width: 1px;
  --btn-border-color: transparent;
  --btn-default-border-radius: var(--option-default-border-radius);
  --btn-rounded-border-radius: var(--option-border-radius-2);
  --btn-circle-border-radius: var(--option-border-radius-full);

  --btn-md-font-size: var(--body1-fs);
  --btn-md-line-height: var(--body1-lh);
  --btn-md-font-weight: var(--fw-normal);
  --btn-md-padding-x: 0.75rem;
  --btn-md-padding-y: 0.25rem;

  --btn-lg-font-size: var(--body1-fs);
  --btn-lg-line-height: var(--body1-lh);
  --btn-lg-padding-x: 1rem;
  --btn-lg-padding-y: 0.5rem;

  --btn-default-font-size: var(--btn-md-font-size);
  --btn-default-line-height: var(--btn-md-line-height);
  --btn-default-font-weight: var(--btn-md-font-weight);
  --btn-default-padding-x: var(--btn-md-padding-x);
  --btn-default-padding-y: var(--btn-md-padding-y);
}
