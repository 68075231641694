:root {
  /* accent */
  --color-accent: 45, 184, 103;
  --ql-color-accent1: rgb(var(--color-accent));

  /* Transparent shades */
  --ql-color-accent1-t-lighten1: rgb(
    var(--color-accent),
    var(--ql-color-blending-lighten1)
  );
  --ql-color-accent1-t-lighten2: rgb(
    var(--color-accent),
    var(--ql-color-blending-lighten2)
  );
  --ql-color-accent1-t-lighten3: rgb(
    var(--color-accent),
    var(--ql-color-blending-lighten3)
  );
  --ql-color-accent1-t-lighten4: rgb(
    var(--color-accent),
    var(--ql-color-blending-lighten4)
  );
  --ql-color-accent1-t-lighten5: rgb(
    var(--color-accent),
    var(--ql-color-blending-lighten5)
  );
  --ql-color-accent1-t-lighten6: rgb(
    var(--color-accent),
    var(--ql-color-blending-lighten6)
  );

  /* dark */
  --color-dark: 37, 48, 55;
  --color-dark-darken: 31, 40, 46;

  --ql-color-dark: rgb(var(--color-dark));

  /* Solid shades */
  --ql-color-dark-lighten1: rgb(42, 57, 66);
  --ql-color-dark-lighten2: rgb(47, 65, 77);
  --ql-color-dark-lighten3: rgb(91, 110, 122);
  --ql-color-dark-lighten4: rgb(122, 141, 153);
  --ql-color-dark-lighten5: rgb(154, 172, 184);
  --ql-color-dark-lighten6: rgb(184, 199, 209);
  --ql-color-dark-lighten7: rgb(211, 222, 229);
  --ql-color-dark-lighten8: rgb(230, 238, 242);
  --ql-color-dark-lighten9: rgb(242, 247, 250);

  --ql-font-family-main: 'GalanoGrotesque', sans-serif;
  --ql-font-family-headings: 'GalanoGrotesque', bold;

  --color-blue-darken: 23, 47, 61;

  /* Outline */
  --ql-outline-color-danger: var(--ql-color-danger-t-lighten4);

  /* Layout */
  --ql-general-layout-px-xxs: 16px; /* mobile (portrait): 0 - 576px */

  --ql-color-dark-t-lighten6: rgba(246, 247, 251, 1);
  --ql-layout-background: var(--ql-color-dark-t-lighten6);

  /* Aside */
  --ql-aside-background: var(--ql-color-white);
  --ql-aside-padding: 24px;

  /* Button */
  --btn-border-color: var(--ql-color-dark-t-lighten4);

  --btn-default-color: var(--ql-typography-text-color-secondary);
  --btn-default-hover-bg: transparent;
  --btn-default-hover-color: var(--ql-color-accent1);

  --btn-text-hover-bg: var(--ql-color-dark-t-lighten5);
  --btn-text-hover-color: var(--ql-color-dark);

  /* Dropdown */
  --dropdown-item-default-bg-hover: var(--ql-color-dark-t-lighten5);
  --dropdown-item-danger-bg-hover: var(--ql-color-danger-t-lighten5);

  /* Menu */
  --ql-menu-item-active-color: var(--ql-color-accent1);
  --ql-aside-width: 76px;
  --ql-menu-item-active-bg: rgb(255, 255, 255);
  --ql-menu-group-title-font-weight: var(--ql-font-weight-semibold);
  --ql-menu-group-title-color: var(--ql-color-dark-t-lighten2);
  --ql-menu-group-title-padding: 8px 24px;

  /* Account */
  --ql-account-avatar-box-shadow-hover: inset 0 0 0 2px var(--ql-color-accent1);
  --ql-form-item-vertical-spacing-without-message: 24px;

  /* TechnologiesCard */
  --technologies-card-selected-bg: var(--ql-color-accent1-t-lighten6);
  --technologies-card-selected-bc: var(--ql-color-accent1-t-lighten4);
  --technologies-card-bc: transparent;

  /* DatasetsCard */
  --dataset-card-selected-bg: var(--ql-color-accent1-t-lighten6);
  --dataset-card-selected-bc: var(--ql-color-accent1-t-lighten4);
  --dataset-card-bc: transparent;

  /* Card */
  --ql-card-border-default: 1px solid var(--ql-color-accent1-t-lighten4);
  --ql-card-border-selected: var(--ql-card-border-default);
  --ql-card-box-shadow-selected: 0px 0px 2px 2px var(--ql-color-accent1);
  --ql-card-box-shadow-default: unset;

  /* Brandbook-Toolbar */
  --ql-toolbar-background: rgba(234, 234, 236, 1);
  --ql-toolbar-box-shadow: var(--ql-shadow-default-left);
  --ql-toolbar-width: 16.25rem;

  /* Color-picker */
  --ql-picker-swatch-background: var(--ql-color-white);
  --ql-picker-swatch-shadow: var(--ql-shadow-default-right);

  /* Border-radius */
  --ql-border-radius-default: 17px;
  --border-radius-square: calc((17 / 48) * 100%);

  --dropdown-item-border-radius-first-child: var(--ql-border-radius-default);
  --dropdown-item-border-radius-last-child: var(--ql-border-radius-default);

  /* Box-shadow */
  --box-shadow-accent: 0px 38px 15px rgba(var(--color-accent), 0.03),
    0px 22px 13px rgba(var(--color-accent), 0.1),
    0px 10px 10px rgba(var(--color-accent), 0.17),
    0px 2px 5px rgba(var(--color-accent), 0.19),
    0px 0px 0px rgba(var(--color-accent), 0.2);
  --box-shadow-default: 0px 107px 43px rgba(var(--color-dark), 0.01),
    0px 60px 36px rgba(var(--color-dark), 0.02),
    0px 27px 27px rgba(var(--color-dark), 0.03),
    0px 7px 15px rgba(var(--color-dark), 0.04),
    0px 0px 0px rgba(var(--color-dark), 0.04);
}
