.brandbook-module {
  --avatar-screen-top: 33%;
  --avatar-screen-right: -6%;

  --avatar-list-top: 0;
  --avatar-list-left: 26px;
}

@media (min-width: 1920px) {
  .brandbook-module {
    --avatar-screen-top: 43%;
    --avatar-screen-right: -12%;
  }
}

@media (max-width: 1920px) {
  .brandbook-module {
    --avatar-screen-top: 43%;
    --avatar-screen-right: 2%;
  }
}

@media (max-width: 768px) {
  .brandbook-module {
    --avatar-screen-top: 58%;

    --avatar-list-top: 20px;
    --avatar-list-left: 10px;
  }
}

@media (max-width: 576px) {
  .brandbook-module {
    --avatar-screen-top: 73%;
    --avatar-screen-right: 4%;

    --avatar-list-left: 26px;
  }
}
