.brandbook-module {
  --btn-grid-column: 3;
}
@media (max-width: 1280px) {
  .brandbook-module {
    --btn-grid-column: 2;
  }
}

@media (max-width: 768px) {
  .brandbook-module {
    --btn-grid-column: 2;
  }
}

@media (max-width: 576px) {
  .brandbook-module {
    --btn-grid-column: 1;
    --btn-ml: 40px;
  }
}
