.offer-module {
  /* TYPOGRAPHY */
  --title-font-family: 'GalanoGrotesque', sans-serif;
  --body-font-family: 'GalanoGrotesque', sans-serif;

  --root-font-size: 1rem; /*  16px */

  /* Font size */
  --h1-fs: 10rem; /* 160px*/
  --h2-fs: 8rem; /*128px*/
  --h3-fs: 5.5rem; /*88px*/
  --h4-fs: 4.5rem; /*72px*/
  --h5-fs: 1.25rem; /*20px*/
  --h6-fs: 1rem; /*16px*/
  --body1-fs: 1.75rem; /*28px*/
  --body2-fs: 1.375rem; /*22px*/
  --caption1-fs: 1.125rem; /*18px*/
  --caption2-fs: 0.625rem; /*10px*/

  /* Line height */
  --h1-lh: 10.5rem; /*168px*/
  --h2-lh: 8.5rem; /*136px*/
  --h3-lh: 6.25rem; /*100px*/
  --h4-lh: 5rem; /*80px*/
  --h5-lh: 2rem; /*32px*/
  --h6-lh: 1.625rem; /*26px*/
  --body1-lh: 2.5rem; /*40px*/
  --body2-lh: 1.75rem; /*28px*/
  --caption1-lh: 1.5rem; /*24px*/
  --caption2-lh: 1rem; /*16px*/

  /* Letter spacing */
  --caption1-ls: 0.125rem; /*2px*/
  --caption2-ls: 0.125rem; /*2px*/

  /* Font weight */
  --fw-bolder: 800;
  --fw-bold: 700;
  --fw-semibold: 600;
  --fw-medium: 500;
  --fw-normal: 400;

  /* TITLE */
  /* h1 */
  --title-h1-font-size: var(--h1-fs);
  --title-h1-line-height: var(--h1-lh);
  --title-h1-font-weight: var(--fw-bolder);
  --title-h1-margin-top: 0;
  --title-h1-margin-bottom: 0;
  --title-h1-letter-spacing: 1px;
  --title-h1-font-family: var(--title-font-family);

  /* h2 */
  --title-h2-font-size: var(--h2-fs);
  --title-h2-line-height: var(--h2-lh);
  --title-h2-font-weight: var(--fw-bolder);
  --title-h2-margin-top: 0;
  --title-h2-margin-bottom: 0;
  --title-h2-letter-spacing: 1px;
  --title-h2-font-family: var(--title-font-family);

  /* h3 */
  --title-h3-font-size: var(--h3-fs);
  --title-h3-line-height: var(--h3-lh);
  --title-h3-font-weight: var(--fw-bolder);
  --title-h3-margin-top: 0;
  --title-h3-margin-bottom: 0;
  --title-h3-letter-spacing: 1px;
  --title-h3-font-family: var(--title-font-family);

  /* h4 */
  --title-h4-font-size: var(--h4-fs);
  --title-h4-line-height: var(--h4-lh);
  --title-h4-font-weight: var(--fw-semibold);
  --title-h4-margin-top: 0;
  --title-h4-margin-bottom: 0;
  --title-h4-letter-spacing: 1px;
  --title-h4-font-family: var(--title-font-family);

  /* h5 */
  --title-h5-font-size: var(--h5-fs);
  --title-h5-line-height: var(--h5-lh);
  --title-h5-font-weight: var(--fw-semibold);
  --title-h5-margin-top: 0;
  --title-h5-margin-bottom: 0;
  --title-h5-letter-spacing: 1px;
  --title-h5-font-family: var(--title-font-family);

  /* h6 */
  --title-h6-font-size: var(--h6-fs);
  --title-h6-line-height: var(--h6-lh);
  --title-h6-font-weight: var(--fw-semibold);
  --title-h6-margin-top: 0;
  --title-h6-margin-bottom: 0;
  --title-h6-letter-spacing: 1px;
  --title-h6-font-family: var(--title-font-family);

  /* subtitle */
  --subtitle-font-size: var(--h5-fs);
  --subtitle-line-height: var(--h5-lh);
  --subtitle-font-weight: var(--fw-regular);
  --subtitle-margin-top: 0;
  --subtitle-margin-bottom: 0;
  --subtitle-letter-spacing: 1px;
  --subtitle-font-family: var(--title-font-family);

  /* TEXT */
  /* Body1 */
  --text-body1-font-size: var(--body1-fs);
  --text-body1-line-height: var(--body1-lh);
  --text-body1-font-weight: var(--fw-normal);
  --text-body1-margin-top: 0;
  --text-body1-margin-bottom: 0;
  --text-body1-letter-spacing: -0.5px;
  --text-body1-font-family: var(--body-font-family);

  /* Body2 */
  --text-body2-font-size: var(--body2-fs);
  --text-body2-line-height: var(--body2-lh);
  --text-body2-font-weight: var(--fw-normal);
  --text-body2-margin-top: 0;
  --text-body2-margin-bottom: 0;
  --text-body2-letter-spacing: -0.5px;
  --text-body2-font-family: var(--body-font-family);

  /* Caption1 */
  --text-caption1-font-size: var(--caption1-fs);
  --text-caption1-line-height: var(--caption1-lh);
  --text-caption1-font-weight: var(--fw-bold);
  --text-caption1-letter-spacing: var(--caption1-ls);
  --text-caption1-margin-top: 0;
  --text-caption1-margin-bottom: 0;
  --text-caption1-font-family: var(--body-font-family);

  /* Caption2 */
  --text-caption2-font-size: var(--caption2-fs);
  --text-caption2-line-height: var(--caption2-lh);
  --text-caption2-font-weight: var(--fw-bold);
  --text-caption2-letter-spacing: var(--caption2-ls);
  --text-caption2-margin-top: 0;
  --text-caption2-margin-bottom: 0;
  --text-caption2-font-family: var(--body-font-family);
}

@media (max-width: 1200px) {
  .offer-module {
    /* Font size */
    --h1-fs: 6rem; /*96px*/
    --h2-fs: 3.5rem; /*56px*/
    --h3-fs: 2.5rem; /*40px*/
    --h4-fs: 1.75rem; /*28px*/
    --h5-fs: 1.25rem; /*20px*/
    --h6-fs: 1rem; /*16px*/
    --body1-fs: 1.25rem; /*20px*/
    --body2-fs: 1.125rem; /*18px*/
    --caption1-fs: 0.875rem; /*14px*/
    --caption2-fs: 0.75rem; /*12px*/

    /* Line height */
    --h1-lh: 6.75rem; /*108px*/
    --h2-lh: 4.25rem; /*68px*/
    --h3-lh: 3rem; /*48px*/
    --h4-lh: 2.25rem; /*36px*/
    --h5-lh: 1.75rem; /*28px*/
    --h6-lh: 1.5rem; /*24px*/
    --body1-lh: 1.75rem; /*28px*/
    --body2-lh: 1.625rem; /*26px*/
    --caption1-lh: 1.25rem; /*20px*/
    --caption2-lh: 1rem; /*16px*/
  }
}

@media (max-width: 575px) {
  .offer-module {
    /* Font size */
    --h1-fs: 3rem; /*48px*/
    --h2-fs: 2rem; /*32px*/
    --h3-fs: 1.75rem; /*28px*/
    --h4-fs: 1.125rem; /*18px*/
    --h5-fs: 1rem; /*16px*/
    --h6-fs: 0.875rem; /*14px*/
    --body1-fs: 1rem; /*16px*/
    --body2-fs: 0.875rem; /*14px*/
    --caption1-fs: 0.75rem; /*12px*/
    --caption2-fs: 0.625rem; /*10px*/

    /* Line height */
    --h1-lh: 3.75rem; /*60px*/
    --h2-lh: 2.75rem; /*44px*/
    --h3-lh: 2.5rem; /*40px*/
    --h4-lh: 1.625rem; /*26px*/
    --h5-lh: 1.5rem; /*24px*/
    --h6-lh: 1.375rem; /*20px*/
    --body1-lh: 1.5rem; /*24px*/
    --body2-lh: 1.375rem; /*22px*/
    --caption1-lh: 1.125rem; /*18px*/
    --caption2-lh: 1rem; /*16px*/
  }
}
