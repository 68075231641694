.brandbook-module {
  --use-of-type-top: 18%;
  --use-of-type-left: 36%;
}
@media (max-width: 1280px) {
  .brandbook-module {
    --use-of-type-top: 6%;
    --use-of-type-left: 38%;
  }
}
@media (max-width: 1024px) {
  .brandbook-module {
    --use-of-type-top: 10%;
    --use-of-type-left: 38%;
  }
}
