.brandbook-module {
  /* Android */
  --app-icon-logo-android-w: 102px;
  --app-icon-logo-android-h: 102px;
  --app-icon-logo-android-br: 50%;
  --app-icon-logo-android-r: 38.5%;
  --app-icon-logo-android-t: 43.6%;

  /* iPhone */
  --app-icon-logo-iphone-w: 135px;
  --app-icon-logo-iphone-h: 135px;
  --app-icon-logo-iphone-br: 28px;
  --app-icon-logo-iphone-r: 71.8%;
  --app-icon-logo-iphone-t: 44.1%;
}

@media (max-width: 1280px) {
  .brandbook-module {
    --app-icon-logo-iphone-w: 88px;
    --app-icon-logo-iphone-h: 88px;
    --app-icon-logo-iphone-r: 71.9%;
    --app-icon-logo-iphone-t: 46.6%;
    --app-icon-logo-iphone-br: 16px;

    --app-icon-logo-android-w: 85px;
    --app-icon-logo-android-h: 85px;
    --app-icon-logo-android-r: 38.4%;
    --app-icon-logo-android-t: 45.3%;
  }
}

@media (max-width: 1024px) {
  .brandbook-module {
    --app-icon-logo-android-w: 82px;
    --app-icon-logo-android-h: 82px;
    --app-icon-logo-android-r: 38.4%;
    --app-icon-logo-android-t: 45.4%;
  }
}

@media (max-width: 768px) {
  .brandbook-module {
    --app-icon-logo-iphone-w: 99px;
    --app-icon-logo-iphone-h: 99px;
    --app-icon-logo-iphone-r: 72%;
    --app-icon-logo-iphone-t: 46.4%;

    --app-icon-logo-android-w: 78px;
    --app-icon-logo-android-h: 78px;
    --app-icon-logo-android-r: 38.5%;
    --app-icon-logo-android-t: 45.3%;
  }
}

@media (max-width: 576px) {
  .brandbook-module {
    --app-icon-logo-iphone-w: 50px;
    --app-icon-logo-iphone-h: 50px;
    --app-icon-logo-iphone-t: 47.3%;
    --app-icon-logo-iphone-br: 6px;

    --app-icon-logo-android-w: 47px;
    --app-icon-logo-android-h: 47px;
    --app-icon-logo-android-r: 38.4%;
    --app-icon-logo-android-t: 47.2%;
  }
}
