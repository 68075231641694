.offer-module {
  --logo-height: 42px;
}
@media (max-width: 1200px) {
  .offer-module {
    --logo-height: 28px;
  }
}
@media (max-width: 576px) {
  .offer-module {
    --logo-height: 22px;
  }
}
