.brandbook-module {
  /* INPUT */
  --input-br: var(--br);

  /* PLACEHOLDER */
  --input-placeholder-lh: var(--text-body1-lh);
  --input-placeholder-ff: var(--ff-primary);
  --input-placeholder-fs: var(--text-body1-fs);

  /* STATES */
  --input-hover-bs: 0px 0px 0px var(--bw-2) var(--input-hover-bc); /* opacity: 0.16*/
  --input-active-bs: 0px 0px 0px var(--bw-2) var(--input-active-bc);

  /* OFFSETS */
  --input-py: var(--offset-12);
  --input-px: var(--offset-16);

  /* LABELS */
  --input-label-lh: var(--text-body1-lh);
  --input-label-fs: var(--text-body1-fs);
  --input-label-ff: var(--ff-primary);
  --input-label-mb: var(--offset-4);
  --input-label-fw: var(--text-fw-medium);

  --input-transform-scale: scale(4);
}

@media (max-width: 1280px) {
  .brandbook-module {
    --input-transform-scale: scale(2.5);
  }
}

@media (max-width: 768px) {
  .brandbook-module {
    --input-transform-scale: scale(2.2);
  }
}

@media (max-width: 576px) {
  .brandbook-module {
    --input-transform-scale: scale(1.3);
  }
}
