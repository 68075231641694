.brandbook-module {
  --line1-left: 88%;
  --line2-left: 92%;
  --line3-left: 96%;
}

@media (max-width: 1024px) {
  .brandbook-module {
    --line1-left: 58%;
    --line2-left: 66%;
    --line3-left: 80%;
  }
}
@media (max-width: 768px) {
  .brandbook-module {
    --line1-left: 60%;
    --line2-left: 70%;
    --line3-left: 84%;
  }
}
@media (max-width: 576px) {
  .brandbook-module {
    --line1-left: 70%;
    --line2-left: 77%;
    --line3-left: 86%;
  }
}
