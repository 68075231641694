:root {
  /*------------ Big play button - start ------------*/
  --qv-big-play-button-bg-color: var(--qv-black);
  --qv-big-play-button-shadow-color: var(--qv-black);
  --qv-big-play-button-shadow-color-hovered: #eee;
  --qv-big-play-button-br: 50%;
  --qv-big-play-button-border-color: var(--qv-gray-5);
  --qv-big-play-button-border-color-hovered: var(--qv-gray-1-t);
  /*------------ Big play button - end ------------*/

  /*------------ Play/pause button - start ------------*/
  --qv-play-pause-button-shadow-color: #eee;
  /*------------ Play/pause button - end ------------*/

  /*------------ Volume bar - start ------------*/
  --qv-volume-level-background-color: var(--qv-black);
  /*------------ Volume bar - end ------------*/

  /*------------ Error notification - start ------------*/
  --qv-error-notification-background-color: #333;
  --qv-error-notification-text-color: var(--qv-white);
  --qv-error-notification-border-color: var(--qv-gray-5);
  /*------------ Error notification - end ------------*/


  /*------------ Player PlayPauseButton - start ------------*/
  --qv-btn-playerPlayPauseBtn-color: var(--qv-white);
  --qv-btn-playerPlayPauseBtn-hover-color: var(--qv-white);
  --qv-btn-playerPlayPauseBtn-bg: var(--qv-gray-8-t);
  --qv-btn-playerPlayPauseBtn-hover-bg:var(--qv-gray-8-t);
  /*------------ Player PlayPauseButton - end ------------*/


  /*------------ Player fullScreenToggle - start ------------*/
  --qv-btn-fullScreenToggle-color: var(--qv-white);
  --qv-btn-fullScreenToggle-hover-color: var(--qv-white);
  --qv-btn-fullScreenToggle-bg: var(--qv-gray-8-t);
  --qv-btn-fullScreenToggle-hover-bg:var(--qv-gray-8-t);
  /*------------ Player fullScreenToggle - end ------------*/

  /*------------ Player currentTime / duration - end ------------*/
  --qv-playerDuration-font-weight: var(--qv-font-weight-light);
  --qv-playerDuration-font-size: var(--qv-font-size-body1);
  --qv-playerDuration-line-height:var(--qv-line-height-body1);
  --qv-playerDuration-color: var(--qv-white);
  --qv-playerDuration-text-shadow:0px 1px 5px var(--qv-gray-10);
  /*------------ Player currentTime / duration - end ------------*/

  /*------------ Player progress-bar - start ------------*/
  --qv-progress-bar-height-init: 4px;
  --qv-progress-bar-height-hovered: 15px;
  --qv-progress-bar-unfilled-bg: var(--qv-white);
  --qv-progress-bar-filled-bg: var(--ql-color-accent1);
  /*------------ Player progress-bar - end ------------*/

  /*------------ Player progress-bar thumb - start ------------*/
  --qv-progress-bar-thumb-width: 0;
  --qv-progress-bar-thumb-height: 0;
  --qv-progress-bar-thumb-hover-width: 0;
  --qv-progress-bar-thumb-hover-height: 0;
  --qv-progress-bar-thumb-box-shadow:  rgba(0, 0, 0, 0.753);
  --qv-progress-bar-thumb-bg:  rgb(255, 255, 255);
  --qv-progress-bar-thumb-br:  var(--qv-border-radius-circle);
  /*------------ Player progress-bar thumb - end ------------*/


  /*\\ Font size - line height //*/ 
  --qv-btn-font-size-sm: var( --qv-font-size-h6); 
  --qv-btn-line-height-sm: var( --qv-line-height-h6); 
  --qv-btn-font-weight-sm: var(--qv-font-weight-regular); 

  --qv-btn-font-size-md: var( --qv-font-size-h6); 
  --qv-btn-line-height-md: var( --qv-line-height-h6); 
  --qv-btn-font-weight-md: var(--qv-font-weight-medium); 


  --qv-btn-font-size-lg: var( --qv-font-size-h5); 
  --qv-btn-line-height-lg: var( --qv-line-height-h5); 
  --qv-btn-font-weight-lg: var(--qv-font-weight-bold); 

  --qv-btn-font-size-xl: var( --qv-font-size-h5); 
  --qv-btn-line-height-xl: var( --qv-line-height-h5); 
  --qv-btn-font-weight-xl: var(--qv-font-weight-bold); 

  /*\\ Button shape //*/ 
  --qv-btn-br-circle: var(--qv-border-radius-circle); 
  --qv-btn-br-round: var(--qv-border-radius-base); 
  --qv-btn-br-sharp: var(--qv-border-radius-none); 

  /*\\ Font family //*/ 
  --qv-btn-font-family:var( --qv-font-family); 

  --qv-btn-default-width: 45px; 
  --qv-btn-default-height: 45px; 

  --qv-btn-width-sm: 25px; 
  --qv-btn-height-sm: 25px; 

  --qv-btn-width-md: var(--qv-btn-default-width); 
  --qv-btn-height-md: var(--qv-btn-default-height); 

  --qv-btn-width-lg: 70px; 
  --qv-btn-height-lg: 70px; 

  --qv-btn-width-xl: 90px; 
  --qv-btn-height-xl: 90px; 

  --qv-btn-hover-scale: 1.05; 
  --qv-btn-transition-speed: 5.25; 

  --qv-btn-default-color: var(--qv-white); 
  --qv-btn-default-hover-color: var(--qv-white); 
  --qv-btn-default-bg: rgba(67, 67, 67, 0.6); 
  --qv-btn-default-disabled-bg: var(--qv-gray-6); 
  --qv-btn-default-hover-bg: rgba(67, 67, 67, 0.8); 

  /*\\ Record start button config //*/ 
  --recordStart-btn-color: var(--qv-white); 
  --qv-btn-recordStart-hover-color: var(--qv-white); 
  --qv-btn-recordStart-bg: rgba(255, 60, 76, 0.6); 
  --qv-btn-recordStart-hover-bg: rgba(255, 60, 76, 1); 

  /*\\ Cancel button config //*/ 
  --qv-btn-cancel-color: var(--qv-btn-default-color); 
  --qv-btn-cancel-hover-color: var(--qv-btn-default-hover-color); 
  --qv-btn-cancel-bg: rgba(0, 0, 0, 0.5); 
  --qv-btn-cancel-hover-bg: rgba(0, 0, 0, 0.7); 

  /*\\ Stop record button config //*/ 
  --qv-btn-recordStop-color: var(--qv-white); 
  --qv-btn-recordStop-hover-color: var(--qv-white); 
  --qv-btn-recordStop-bg: var(--qv-gray-4-t); 
  --qv-btn-recordStop-hover-bg: var(--qv-gray-4-t); 
  --qv-btn-recordStopInner-color: var(--qv-white); 
  --qv-btn-recordStopInner-hover-color: var(--qv-white); 
  --qv-btn-recordStopInner-bg: rgba(255, 60, 76, 0.6); 
  --qv-btn-recordStopInner-hover-bg: rgba(255, 60, 76, 0.6); 

  /*\\ Approve button config //*/ 
  --qv-btn-approve-color: var(--qv-white); 
  --qv-btn-approve-hover-color: var(--qv-white); 
  --qv-btn-approve-bg: rgba(82, 196, 26, 0.6); 
  --qv-btn-approve-hover-bg: rgba(82, 196, 26, 1); 

  /*\\ Reject button config //*/ 
  --qv-btn-reject-color: var(--qv-white); 
  --qv-btn-reject-hover-color: var(--qv-white); 
  --qv-btn-reject-bg: rgba(255, 60, 76, 0.6); 
  --qv-btn-reject-hover-bg:rgba(255, 60, 76, 1); 

  /*\\ Replay button config //*/ 
  --qv-btn-replay-color: var(--qv-white); 
  --qv-btn-replay-hover-color: var(--qv-white); 
  --qv-btn-replay-bg: var(--qv-gray-8-t); 
  --qv-btn-replay-hover-bg:var(--qv-gray-8-t); 

  /*\\ Screen recorder button //*/ 
  --qv-btn-screenRecord-bg: var(--qv-gray-9-t); 
  --qv-btn-screenRecord-hover-bg: var(--qv-btn-default-hover-bg); 
  --qv-btn-screenRecord-color: var(--qv-white); 
  --qv-btn-screenRecord-hover-color: var(--qv-white); 
  /*\\ Screen recorder button //*/ 

  /*\\ Camera recorder button //*/ 
  --qv-btn-cameraRecord-bg: var(--qv-gray-9-t); 
  --qv-btn-cameraRecord-hover-bg: var(--qv-btn-default-hover-bg); 
  --qv-btn-cameraRecord-color: var(--qv-white); 
  --qv-btn-cameraRecord-hover-color: var(--qv-white); 
  /*\\ Camera recorder button //*/ 

  /*\\ Upload button //*/ 
  --qv-btn-upload-bg: var(--qv-gray-9-t); 
  --qv-btn-upload-hover-bg: var(--qv-btn-default-hover-bg); 
  --qv-btn-upload-color: var(--qv-white); 
  --qv-btn-upload-hover-color: var(--qv-white); 
  /*\\ Upload button //*/ 

  /*\\ library button //*/ 
  --qv-btn-library-bg: var(--qv-gray-9-t); 
  --qv-btn-library-hover-bg: var(--qv-btn-default-hover-bg); 
  --qv-btn-library-color: var(--qv-white); 
  --qv-btn-library-hover-color: var(--qv-white); 
  /*\\ library button //*/  

  /*\\ Picture in picture button config //*/ 
  --qv-btn-pip-font-size: var(--qv-font-size-base); 
  --qv-btn-pip-color: var(--qv-white); 
  --qv-btn-pip-hover-color: var(--qv-white); 
  --qv-btn-pip-bg: var(--qv-gray-9-t); 
  --qv-btn-pip-hover-bg: var(--qv-gray-9); 

  /*\\ dropdown settings icon //*/ 
  --qv-btn-badge-icon-font-size: var( --qv-font-size-body1); 
  --qv-btn-badge-icon-shadow-color: var(--qv-gray-9); 
  /*\\ dropdown settings icon //*/ 

  /*\\ Device dropdown item //*/ 
  --qv-dropdown-item-bg: var(--qv-gray-3); 
  --qv-dropdown-item-bg-box-shadow-color: var(--qv-gray-9); 
  /*\\ Device dropdown item //*/ 

  /*\\  dropdown item text //*/ 
  --qv-dropdown-item-text-font-size: var(--qv-font-size-body2); 
  --qv-dropdown-item-text-line-height:var(--qv-line-height-body2); 
  --qv-dropdown-item-text-bg-hover: var(--qv-gray-5); 
  --qv-dropdown-item-text-border-color: var(--qv-gray-5); 
  /*\\  dropdown item text//*/ 

  /*\\  dropdown settings //*/ 
  --devices-dropdown-settings-icon-text-font-size: var(--qv-font-size-body1); 
  --devices-dropdown-settings-icon-box-shadow-color: var(--qv-gray-9); 
  /*\\  dropdown settings //*/ 

  /*\\ Device dropdown default selected device //*/ 
  --qv-dropdown-default-selected-device-font-size: var(--qv-font-size-caption2); 
  /*\\ Device dropdown default selected device  //*/ 

  /*\\ audioDeviceSelect button //*/ 
  --qv-btn-audioDeviceSelect-bg: var(--qv-gray-9-t); 
  --qv-btn-audioDeviceSelect-hover-bg: var(--qv-btn-default-hover-bg); 
  --qv-btn-audioDeviceSelect-color: var(--qv-white); 
  --qv-btn-audioDeviceSelect-hover-color: var(--qv-white); 
  /*\\ audioDeviceSelect button //*/ 

  /*\\ videoDeviceSelect button //*/ 
  --qv-btn-videoDeviceSelect-bg: var(--qv-gray-9-t); 
  --qv-btn-videoDeviceSelect-hover-bg: var(--qv-btn-default-hover-bg); 
  --qv-btn-videoDeviceSelect-color: var(--qv-white); 
  --qv-btn-videoDeviceSelect-hover-color: var(--qv-white); 
  /*\\ videoDeviceSelect button //*/

  --qv-recorder-bg:var(--qv-white); 
  --qv-playerRecorder-br: 40px; 
  --qv-switcherBtn-checked-bg: var(--ql-color-accent1);
  --qv-btn-chaptersApprove-hover-bg:var(--ql-color-accent1);
  --qv-btn-default-disabled-color:var(--color-white);
  --qv-btn-linkApprove-bg:var(--ql-color-accent1);
  --qv-btn-linkApprove-hover-bg:var(--ql-color-accent1);
}