/*
    This config is used for Grid system (Container/Row/Col).
    Be sure to match these config with the breakpoints.js
*/

.brandbook-module {
  --grid-columns: 12;

  --grid-gutter-default: 16px;
  --grid-gutter-sm: 8px;
  --grid-gutter-md: 12px;
  --grid-gutter-lg: 16px;
  --grid-gutter-xl: 16px;
  --grid-gutter-xxl: 16px;

  --grid-negative-gutter-default: -8px;
  --grid-negative-gutter-sm: -8px;
  --grid-negative-gutter-md: -12px;
  --grid-negative-gutter-lg: -16px;
  --grid-negative-gutter-xl: -16px;
  --grid-negative-gutter-xxl: -16px;

  --grid-container-max-width-sm: 534px;
  --grid-container-max-width-md: 720px;
  --grid-container-max-width-lg: 960px;
  --grid-container-max-width-xl: 1216px;
  --grid-container-max-width-xxl: 1350px;
}
