.brandbook-module {
  --offset-0: 0;
  --offset-4: 0.25rem;
  --offset-8: 0.5rem;
  --offset-12: 0.75rem;
  --offset-16: 1rem;
  --offset-20: 1.25rem;
  --offset-24: 1.5rem;
  --offset-32: 2rem;
  --offset-40: 2.5rem;
  --offset-48: 3rem;
  --offset-64: 4rem;
}
