.brandbook-module {
  --icon-view-width: 176px;
}

@media (max-width: 1280px) {
  .brandbook-module {
    --icon-view-width: 154px;
  }
}
@media (max-width: 768px) {
  .brandbook-module {
    --icon-view-width: 140px;
  }
}
