.brandbook-module {
  /* Switcher */
  --switcher-transform-scale: scale(20);
  --switcher-transition: 0.4s;
  --switcher-slider-transform-default: translateX(
    calc(
      var(--switcher-slider-width-default) -
        (var(--switcher-slider-left-default) * 2)
    )
  );

  /* Switcher size */
  --switcher-height-default: 1rem;
  --switcher-width-default: 1.75rem;

  /* Switcher border radius */
  --switcher-br-default: calc(var(--switcher-height-default) / 2);

  /* Switcher slider size */
  --switcher-slider-height-default: 0.875rem;
  --switcher-slider-width-default: 0.875rem;

  /* Switcher slider border radius */
  --switcher-slider-br: 50%;

  /* Switcher slider offsets */
  --switcher-slider-left-default: 1px;
  --switcher-slider-bottom-default: 1px;
}

@media (max-width: 1280px) {
  .brandbook-module {
    --switcher-transform-scale: scale(15);
  }
}

@media (max-width: 768px) {
  .brandbook-module {
    --switcher-transform-scale: scale(10);
  }
}

@media (max-width: 576px) {
  .brandbook-module {
    --switcher-transform-scale: scale(7);

  }
}
