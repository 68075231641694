.brandbook-module {
  --radio-m: 0 var(--offset-12) 0 0; /* 12px */
  --radio-mr: var(--offset-32); /* 32px */

  --radio-width: 1rem; /* 16px */
  --radio-height: 1rem; /* 16px */
  --radio-fs: var(--text-body1-fs); /* 16px */
  --radio-lh: var(--text-body1-lh); /* 24px */
  --radio-ff: var(--ff-primary);

  /* border */
  --radio-bw: 1px;
  --radio-bw-bold: 5px;
  --radio-br: 50%;

  --radio-transform-scale: scale(20);
}

@media (max-width: 1280px) {
  .brandbook-module {
    --radio-transform-scale: scale(15);
  }
}

@media (max-width: 768px) {
  .brandbook-module {
    --radio-transform-scale: scale(10);
  }
}

@media (max-width: 576px) {
  .brandbook-module {
    --radio-transform-scale: scale(7);

  }
}
