.brandbook-module {
  --footer-z-index: 2;
  /* TEXT */
  --footer-fs: var(--text-body1-fs);
  --footer-lh: var(--text-body1-lh);
  --footer-ff: var(--ff-primary);
  --footer-fw: var(--text-fw-semibold);
}

@media (max-width: 1920px), (min-width: 1920px) {
  .brandbook-module {
    --footer-px: var(--offset-64);
    --footer-py: var(--offset-48);
  }
}
@media (max-width: 1280px) {
  .brandbook-module {
    --footer-px: var(--offset-48);
    --footer-py: var(--offset-40);
  }
}
@media (max-width: 1024px) {
  .brandbook-module {
    --footer-px: var(--offset-40);
    --footer-py: var(--offset-32);
  }
}
@media (max-width: 768px) {
  .brandbook-module {
    --footer-px: var(--offset-32);
    --footer-py: var(--offset-24);
  }
}

@media (max-width: 576px) {
  .brandbook-module {
    --footer-px: var(--offset-24);
    --footer-py: var(--offset-20);
  }
}
