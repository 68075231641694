.brandbook-module {
  --typo-intro-width: 384px;
}

@media (max-width: 1280px) {
  .brandbook-module {
    --typo-intro-width: 360px;
  }
}

@media (max-width: 576px) {
  .brandbook-module {
    --typo-intro-width: 240px;
    text-align: justify;
  }
}
