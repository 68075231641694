.brandbook-module {
  --input-grid-column: 4;
  --input-grid-width: 280px;
  --input-row-gap: var(--gap-18);
}
@media (max-width: 1410px) {
  .brandbook-module {
    --input-grid-column: 3;
  }
}

@media (max-width: 1110px) {
  .brandbook-module {
    --input-grid-column: 2;
  }
}

@media (max-width: 768px) {
  .brandbook-module {
    --input-grid-column: 2;
  }
}

@media (max-width: 576px) {
  .brandbook-module {
    --input-grid-column: 1;
    --input-ml: 10px;
  }
}
