   .brandbook-module {
       --checkbox-wrapper-direction: column;
       --checkbox-list-direction: row;
   }

@media (max-width: 980px) {
    .brandbook-module {
        --checkbox-wrapper-direction: row;
        --checkbox-list-direction: column;
    }
}

@media (max-width: 768px) {
    .brandbook-module {
        --checkbox-wrapper-direction: row;
        --checkbox-list-direction: column;
    }
}

@media (max-width: 576px) {
    .brandbook-module {
        --checkbox-wrapper-direction: row;
        --checkbox-list-direction: column;

    }
}
