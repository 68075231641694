.brandbook-module {
  --typo-top: 22%;
  --typo-left: 42%;
}
@media (max-width: 1440px) {
  .brandbook-module {
    --typo-top: 22%;
    --typo-left: 48%;
  }
}
@media (max-width: 1280px) {
  .brandbook-module {
    --typo-top: 30%;
    --typo-left: 46%;
  }
}
@media (max-width: 1024px) {
  .brandbook-module {
    --typo-top: 70%;
    --typo-left: 36%;
  }
}
@media (max-width: 768px) {
  .brandbook-module {
    --typo-top: 62%;
    --typo-left: 22%;
  }
}
@media (max-width: 576px) {
  .brandbook-module {
    --typo-top: 65%;
    --typo-left: 10%;
  }
}
