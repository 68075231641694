.offer-module {
  --navigation-dot-size: 0.625rem;
  --navigation-tooltip-offset: 2rem;
  --navigation-dots-offset: 3rem;
}
@media (max-width: 1440px) {
  .offer-module {
    --navigation-dot-size: 0.5rem;
    --navigation-tooltip-offset: 1rem;
  }
}
@media (max-width: 1280px) {
  .offer-module {
    --navigation-dot-size: 0.5rem;
    --navigation-tooltip-offset: 1rem;
  }
}
@media (max-width: 1024px) {
  .offer-module {
    --navigation-dots-offset: 2rem;
  }
}
@media (max-width: 576px) {
  .offer-module {
    --navigation-dot-size: 0.5rem;
    --navigation-tooltip-offset: 1rem;
    --navigation-dots-offset: 1.7rem;
  }
}
