.brandbook-module {
  --icon-list-max-width: 800px;
  --icon-list-max-height: 600px;
}

@media (max-width: 1024px) {
  .brandbook-module {
    --icon-list-max-width: 440px;
  }
}
