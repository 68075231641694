.senseteq {
  /* Colors */
  /* Alfa */
  --alpha-default: 100%;
  --alpha-1: 72%;
  --alpha-1: 40%;
  --alpha-3: 32%;
  --alpha-4: 20%;
  --alpha-5: 10%;
  --alpha-6: 5%;

  /* Accent */
  --senseteq-accent: 45, 184, 103;
  --senseteq-accent-default: rgba(
    var(--senseteq-accent),
    var(--alpha-default)
  ); /* 100% */

  /* Neutral (Dark) */
  --senseteq-neutral: 37, 48, 55;
  --senseteq-neutral-default: rgba(
    var(--senseteq-neutral),
    var(--alpha-default)
  ); /* 100% */

  /* Transparent */
  --senseteq-neutral-t-lighten1: rgba(
    var(--senseteq-neutral-default),
    var(--alpha-1)
  ); /* 72% */

  /* TYPOGRAPHY */
  --ff-senseteq: 'GalanoGrotesque', sans-serif;
  /* Title */
  --senseteq-title-fs: 9rem;
  --senseteq-title-lh: 9rem;
  /* Subtitle */
  --senseteq-text-subtitle-fs: 1.5rem;
  --senseteq-text-subtitle-lh: 2rem;
  /* Text */
  --senseteq-text-body-fs: 1rem;
  --senseteq-text-body-lh: 1.5rem;

  /* Colors */
  /* Accent */
  --accent-default: var(--senseteq-accent-default);

  /* Neutral */
  --neutral-default: var(--senseteq-neutral-default);
  --neutral-t-lighten1: var(--senseteq-neutral-t-lighten1);

  /* TEXT  */
  --senseteq-text-accent: var(--accent-default);

  /* BG  */
  --senseteq-white-bg: var(--white-bg);
  --senseteq-screen-bg: var(--senseteq-white-bg);

  /* Title */
  --title-mega1-fs: var(--senseteq-title-fs);
  --title-mega1-lh: var(--senseteq-title-lh);

  /* Subtitle */
  --text-subtitle1-fs: var(--senseteq-text-subtitle-fs);
  --text-subtitle1-lh: var(--senseteq-text-subtitle-lh);

  /* Text */
  --text-body1-fs: var(--senseteq-text-body-fs);
  --text-body1-lh: var(--senseteq-text-body-lh);
}

@media (max-width: 1024px) {
  .senseteq {
    --senseteq-title-fs: 7.5rem; /* 120px */
    --senseteq-title-lh: 8.375rem; /* 134px */

    --senseteq-text-subtitle-fs: 1rem; /* 16px */
    --senseteq-text-subtitle-lh: 1.5rem; /* 24px */
  }
}

@media (max-width: 768px) {
  .senseteq {
    --senseteq-title-fs: 5rem; /* 80px */
    --senseteq-title-lh: 5.75rem; /* 92px */
  }
}

@media (max-width: 576px) {
  .senseteq {
    --senseteq-title-fs: 3.625rem; /* 58px */
    --senseteq-title-lh: 5.25rem; /* 64px */
  }
}
