.brandbook-module {
  --checkbox-screen-top: 42%;
  --checkbox-screen-right: -4%;
}

@media (min-width: 1920px) {
  .brandbook-module {
    --checkbox-screen-right: -18%;
  }
}
@media (max-width: 1920px) {
  .brandbook-module {
    --checkbox-screen-right: 0%;
  }
}
@media (max-width: 1440px) {
  .brandbook-module {
    --checkbox-screen-right: 0%;
  }
}
@media (max-width: 1280px) {
  .brandbook-module {
    --checkbox-screen-right: -1%;
  }
}
@media (max-width: 768px) {
  .brandbook-module {
    --checkbox-screen-right: -4%;
  }
}
@media (max-width: 576px) {
  .brandbook-module {
    --checkbox-screen-right: 0%;
  }
}
