.brandbook-module {
  --input-bottom: -12%;
  --input-right: 0%;
}

@media (max-width: 1280px) {
  .brandbook-module {
    --input-bottom: -8%;
    --input-right: 0%;
  }
}

@media (max-width: 768px) {
  .brandbook-module {
    --input-bottom: -8%;
    --input-right: -3%;
  }
}

@media (max-width: 576px) {
  .brandbook-module {
    --input-bottom: -9%;
    --input-right: -5%;
  }
}
