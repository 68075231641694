.brandbook-module {
  /* TODO: change font-family */
  /* Font weight */
  --title-fw-bold: 700;
  --title-fw-semibold: 600;
  --title-fw-medium: 500;
  --title-fw-regular: 400;

  /* Letter spacing */
  --title-letter-spacing: 0.25rem;

  /* TYPOGRAPHY */
  --ff-secondary: 'GalanoGrotesque', sans-serif;

  /* Font size */
  --title-mega1-fs: 12rem; /* 192px */
  --title-mega2-fs: 10rem; /* 160px */
  --title-h1-fs: 3.75rem; /* 60px */
  --title-h2-fs: 3rem; /* 48px */
  --title-h3-fs: 2.5rem; /* 40px */
  --title-h4-fs: 2rem; /* 32px */
  --title-h5-fs: 1.5rem; /* 24px */
  --title-h6-fs: 1rem; /* 16px */

  /* Line height */
  --title-mega1-lh: 12rem; /* 192px */
  --title-mega2-lh: 10rem; /* 160px */
  --title-h1-lh: 4.5rem; /* 72px */
  --title-h2-lh: 3.5rem; /* 56px */
  --title-h3-lh: 3rem; /* 48px */
  --title-h4-lh: 2.5rem; /* 40px */
  --title-h5-lh: 2rem; /* 32px */
  --title-h6-lh: 1.5rem; /* 24px */

  /* Text */
  /* Font weight */
  --text-fw-bold: 700;
  --text-fw-semibold: 600;
  --text-fw-medium: 500;
  --text-fw-regular: 400;

  --ff-primary: 'GalanoGrotesque', sans-serif;

  --text-caption1-letter-spacing: 0.125rem;
  --text-caption2-letter-spacing: 0.25rem;

  /* Font size */
  --text-alphabet-fs: 3.375rem; /* 54px */
  --text-subtitle1-fs: 2rem; /* 32px */
  --text-subtitle2-fs: 1.5rem; /* 24px */
  --text-body1-fs: 1rem; /* 16px */
  --text-body2-fs: 0.875rem; /* 14px */
  --text-caption1-fs: 0.625rem; /* 10px */
  --text-caption2-fs: 1.8rem; /* 24px */

  /* Line height */
  --text-alphabet-lh: 5rem; /* 80px */
  --text-subtitle1-lh: 2.5rem; /* 40px */
  --text-subtitle2-lh: 2rem; /* 32px */
  --text-body1-lh: 1.5rem; /* 24px */
  --text-body2-lh: 1.25rem; /* 20px */
  --text-caption1-lh: 0.75rem; /* 12px */
  --text-caption2-lh: 3.6rem; /* 48px */
}

@media (max-width: 1280px) {
  .brandbook-module {
    /* Font size */
    --title-mega2-fs: 8rem; /* 128px */
    --text-alphabet-fs: 2.8rem; /* 44.8px */
    --text-subtitle1-fs: 2rem; /* 32px */
    --text-caption2-lh: 2.3rem;

    /* Line height */
    --title-mega2-lh: 8.5rem; /* 136px */
    --text-alphabet-lh: 4.5rem; /* 72px */
    --text-subtitle1-lh: 3rem; /* 48px */
    --text-caption2-fs: 1.1rem;
  }
}

@media (max-width: 1024px) {
  .brandbook-module {
    /* Font size */
    --title-mega2-fs: 8rem; /* 128px */
    --text-alphabet-fs: 2.8rem; /* 44.8px */
    --text-subtitle1-fs: 2rem; /* 32px */
    --text-caption2-lh: 2.3rem;

    /* Line height */
    --title-mega2-lh: 8.5rem; /* 136px */
    --text-alphabet-lh: 4.5rem; /* 72px */
    --text-subtitle1-lh: 3rem; /* 48px */
    --text-caption2-fs: 1.1rem;
  }
}

@media (max-width: 768px) {
  .brandbook-module {
    /* Font size */
    --title-mega2-fs: 4.5rem; /* 56px */
    --text-caption2-fs: 1.22rem; /* 10px */
    --text-alphabet-fs: 1.6rem; /* 22.4px */

    /* Line height */
    --title-mega2-lh: 5.5rem; /* 64px */
    --text-caption2-lh: 2.7rem;
    --text-alphabet-lh: 2.3rem; /* 46px */
  }
}

@media (max-width: 576px) {
  .brandbook-module {
    /* Font size */
    --title-mega2-fs: 3rem; /* 48px */

    --title-h1-fs: 2.375rem; /* 38px */
    --title-h2-fs: 1.875rem; /* 30px */
    --title-h3-fs: 1.5rem; /* 24px */
    --title-h4-fs: 1.25rem; /* 20px */
    --title-h5-fs: 1rem; /* 16px */
    --title-h6-fs: 0.875rem; /* 14px */

    --text-alphabet-fs: 1.2rem; /* 22.4px */

    /* Line height */
    --title-mega2-lh: 3.5rem; /* 56px */

    --title-h1-lh: 2.875rem; /* 46px */
    --title-h2-lh: 2.375rem; /* 38px */
    --title-h3-lh: 2rem; /* 32px */
    --title-h4-lh: 1.75rem; /* 28px */
    --title-h5-lh: 1.5rem; /* 24px */

    --text-alphabet-lh: 1.9rem; /* 46px */

    /* Font Size */
    --text-subtitle1-fs: 1.5rem; /* 24px */
    --text-subtitle2-fs: 1.25rem; /* 20px */
    --text-body1-fs: 1rem; /* 16px */
    --text-body2-fs: 0.875rem; /* 14px */
    --text-caption1-fs: 0.625rem; /* 10px */
    --text-caption2-fs: 0.64rem; /* 9px */

    /* Line height */
    --text-subtitle1-lh: 2rem; /* 32px */
    --text-subtitle2-lh: 1.75rem; /* 28px */
    --text-body1-lh: 1.5rem; /* 24px */
    --text-body2-lh: 1.25rem; /* 20px */
    --text-caption1-lh: 0.75rem; /* 12px */
    --text-caption2-lh: 1.3rem; /* 18px */
  }
}
