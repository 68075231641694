.brandbook-module {
  /* TEXT */
  --header-fs: var(--text-body1-fs);
  --header-lh: var(--text-body1-lh);
  --header-ff: var(--ff-primary);
}

@media (max-width: 1920px), (min-width: 1920px) {
  .brandbook-module {
    --header-px: var(--offset-64);
    --header-py: var(--offset-48);
  }
}
@media (max-width: 1280px) {
  .brandbook-module {
    --header-px: var(--offset-48);
    --header-py: var(--offset-40);
  }
}
@media (max-width: 1024px) {
  .brandbook-module {
    --header-px: var(--offset-40);
    --header-py: var(--offset-32);
  }
}
@media (max-width: 768px) {
  .brandbook-module {
    --header-px: var(--offset-32);
    --header-py: var(--offset-24);
  }
}
@media (max-width: 576px) {
  .brandbook-module {
    --header-px: var(--offset-20);
    --header-py: var(--offset-16);
  }
}
