.brandbook-module {
  --content-list-top: 0;
  --content-list-right: 0;
}

@media (max-width: 576px) {
  .brandbook-module {
    --content-list-top: 2%;
    --content-list-right: 6%;
  }
}
