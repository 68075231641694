@media (max-width: 1920px) {
  .brandbook-module {
    --aside-ml: var(--offset-40);
  }
}
@media (max-width: 1600px) {
  .brandbook-module {
    --aside-ml: var(--offset-40);
  }
}
@media (max-width: 1440px) {
  .brandbook-module {
    --aside-ml: var(--offset-40);
    --title-max-w: 384px;
  }
}
@media (max-width: 1280px) {
  .brandbook-module {
    --aside-ml: var(--offset-32);
  }
}
@media (max-width: 1024px) {
  .brandbook-module {
    --aside-ml: var(--offset-32);
    --title-max-w: 284px;
  }
}
@media (max-width: 768px) {
  .brandbook-module {
    --aside-ml: var(--offset-32);
  }
}
@media (max-width: 576px) {
  .brandbook-module {
    --aside-ml: var(--offset-16);
    --title-max-w: 184px;
  }
}
