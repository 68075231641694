.brandbook-module {
  --alphabet-width: 384px;
  --alphabet-text-align: left;
}

@media (max-width: 1440px) {
  .brandbook-module {
    --alphabet-width: 368px;
    --alphabet-text-align: left;
  }
}
@media (max-width: 1280px) {
  .brandbook-module {
    --alphabet-width: 356px;
    --alphabet-text-align: left;
  }
}
@media (max-width: 1024px) {
  .brandbook-module {
    --alphabet-width: 320px;
    --alphabet-text-align: right;
  }
}
@media (max-width: 768px) {
  .brandbook-module {
    --alphabet-width: 274px;
    --alphabet-text-align: right;
  }
}
@media (max-width: 576px) {
  .brandbook-module {
    --alphabet-width: 224px;
    --alphabet-text-align: right;
  }
}
