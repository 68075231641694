@media print {
  * {
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
  @page {
    size: auto;
    margin: 0cm;
    position: relative;
  }
  section {
    counter-increment: pageCounter;
    break-after: always;
  }
  .offer-print-hide-models-screen {
    display: none !important;
  }
  #pageFooter:after {
    content: counter(pageCounter);
    color: var(--text-color-secondary);
    font-size: var(--text-caption1-font-size);
  }
  #navigation-dots {
    display: none;
  }
  .offer-print-timeline-description {
    font-size: 12pt !important;
  }
  .offer-print-feature-img {
    height: 2.5cm !important;
  }
  .offer-print-technology-img {
    height: 3cm !important;
  }
  .offer-print-screen-title {
    text-align: left !important;
  }
  .offer-print-greeting-screen-subtitle {
    position: absolute !important;
    top: 20% !important;
  }
  .offer-module .col-lg-4 {
    width: 33.33333333% !important;
  }
  .offer-module .col-xl-3 {
    width: 25% !important;
  }
  .offer-module .col-lg-7 {
    width: 58.33333333% !important;
  }
  .offer-module .col-lg-5 {
    width: 41.66666667% !important;
  }
}
